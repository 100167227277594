<template>
  <div class="page-manager" v-loading="leftLoading">
    <div class="page-search-bar" style="z-index: 1;">
      <el-select class="search-select" v-model="keyword" placeholder="搜索页面"
                 size="medium" filterable clearable @change="handleSearch">
        <template #prefix><i class="el-icon-search" style="line-height: 2.6; margin-left: 5px;" /></template>
        <el-option-group v-if="customTree.data && customTree.data.length" label="自定义页面">
          <el-option v-for="c in allCustomPages" :key="c.id" :label="c.name" :value="`id#${c.id}`" />
        </el-option-group>
        <el-option-group v-if="systemTree.data && systemTree.data.length" label="系统页面">
          <el-option v-for="c in allSystemPages" :key="c.system_page" :label="c.name" :value="`s#${c.system_page}`" />
        </el-option-group>
      </el-select>
    </div>
    <!-- 是否点击展开子节点 -->
    <!-- :expand-on-click-node="false"-->
    <el-tree ref="customTree" :data="customTree.data" node-key="id" :props="customTree.defaultProps"
      default-expand-all :allow-drag="allowDrag" :allow-drop="allowDrop" :expand-on-click-node="false"
      @node-drag-start="handleDragStart" @node-drag-end="handleDragEnd" @node-click="handleClickFirst"
      draggable>
      <div class="custom-tree-node" :id="data.id"
        :class="[pageDetail && (pageDetail.id === data.id) ? 'active' : '', showId === data.id ? 'hover' : '']"
        slot-scope="{ node, data }">
        <div class="page-title" @click="handleTreeNodeClick(data, node)">
          <template v-if="node.level > 1">
            <img v-if="!data.is_index" :src="pageDetail && pageDetail.id === data.id ? pageIcon[1] : pageIcon[0]" />
            <img v-else :src="pageDetail && pageDetail.id === data.id ? homeIcon[1] : homeIcon[0]" />
          </template>
          <span class="label" :title="node.label">{{node.label}}</span>
        </div>
        <!-- 重命名输入框 -->
        <!--        <div v-if="customTree.inputId === data.id">-->
        <!--          <el-input v-if="customTree.inputId === data.id" type="text" size="mini" class="rename-input"-->
        <!--                    v-model="customTree.inputValue" placeholder="请输入页面名称" v-focus-->
        <!--                    @keyup.enter.native="$event.target.blur" @blur="saveRename(node.label)"-->
        <!--          />-->
        <!--        </div>-->
        <!-- 新增页面 -->
        <div class="icon icon-plus" v-if="node.level === 1" @click.stop="newPage()">
          <el-tooltip content="新增页面"><i class="el-icon-plus" :style="{cursor: lock ? 'not-allowed':''}" />
          </el-tooltip>
        </div>
        <!-- 操作名称：设为首页、重新命名、添加子级、复制、删除 -->
        <div class="icon icon-more" v-if="node.level > 1">
          <el-popover popper-class="page-manager-popover" :ref="`popover-${data.id}`" trigger="click"
            placement="right-start" @hide="setHover()">
            <i slot="reference" @click.stop="markLastId(data.id)" class="el-icon-more" />
            <div class="flex-col">
              <span class="pop-item" @click="setIndex(data)">{{data.is_index ? '取消设为首页' : '设为首页'}}</span>
              <!-- <span @click.stop="renamePage(data.id, node.label)" class="pop-item">重新命名</span> -->
              <span @click.stop="newPage(node.level === 2 ? null : data.pid, '', data.id, 1)"
                class="pop-item">上方添加页面</span>
              <span @click.stop="newPage(node.level === 2 ? null : data.pid, '', data.id, 2)"
                class="pop-item">下方添加页面</span>
              <span v-if="node.level < 4" @click.stop="newPage(data.id)" class="pop-item">添加子级</span>
              <span @click.stop="newPage(node.level === 2 ? null : data.pid, node.label, data.id, 2)"
                class="pop-item">复制</span>
              <span @click.stop="delPage(data)" class="pop-item">删除</span>
              <template v-if="$route.query.pageset">
                <span :disabled="!data.cover" @click.stop="showPageThumbnail(data)" class="pop-item">预览封面图</span>
                <span @click.stop="generatePageThumbnail(data)" class="pop-item">{{(data.cover ? '重新' : '') + '生成封面图'}}</span>
              </template>
              <span v-if="pageDetail && pageDetail.id === data.id && isTestVer" @click.stop="copyPageDetail"
                class="pop-item">复制配置</span>
              <span v-if="isTestVer" @click.stop="copyPageID(data.id)"
                class="pop-item">复制ID</span>
            </div>
          </el-popover>
        </div>
      </div>
    </el-tree>
    <!-- 系统页面 -->
    <!-- :expand-on-click-node="false"-->
    <el-tree :data="systemTree.data" node-key="system_page" :props="systemTree.defaultProps"
      default-expand-all :expand-on-click-node="false" @node-click="handleTreeNodeClick">
      <div class="custom-tree-node" :id="data.system_page"
        :class="[pageDetail && (pageDetail.system_page === data.system_page) ? 'active' : '']"
        slot-scope="{ node, data }">
        <div class="page-title">
          <img v-if="node.level > 1"
            :src="pageDetail && pageDetail.system_page === data.system_page ? pageIcon[1] : pageIcon[0]" />
          <span class="label" :title="node.label">{{node.label}}</span>
        </div>
        <!-- 操作名称：设为首页、重新命名、添加子级、复制、删除 -->
        <div class="icon icon-more" v-if="isTestVer && node.level > 1">
          <el-popover popper-class="page-manager-popover" :ref="`popover-${data.system_page}`" trigger="click"
                      placement="right-start" @hide="setHover()">
            <i slot="reference" @click.stop="markLastId(data.system_page)" class="el-icon-more" />
            <div class="flex-col">
              <span v-if="isTestVer" @click.stop="copyPageID(data.system_page)"
                    class="pop-item">复制标识</span>
            </div>
          </el-popover>
        </div>
      </div>
    </el-tree>
  </div>
</template>

<script>
  import {
    deletePage,
    getPageList,
    renamePage,
    setCover,
    updateLevelAndSort,
    setIndex
  } from '../../api/page'

import { createNamespacedHelpers } from "vuex";
import { toBlob } from 'html-to-image'
import filesUpload from "@/base/utils/upload4";

// let library = require("@/modules/app-design/assets/jsons/component-library.json");

const { mapState } = createNamespacedHelpers("appDesign");

export default {
  name: "PageManager",
  inject: ['postData'],
  data() {
    return {
      leftLoading: false,
      keyword: '',
      customTree: {
        /* 页面（自定义、系统）列表 */
        data: null,
        defaultProps: {
          children: "_children",
          label: "name",
        },
      },
      systemTree: {
        data: null,
        defaultProps: {
          children: "_children",
          label: "name",
        },
      },
      showId: -1,
      pageIcon: [
        require("../../assets/images/left/page.png"),
        require("../../assets/images/left/page-active.png"),
      ],
      homeIcon: [
        require("../../assets/images/left/home.png"),
        require("../../assets/images/left/home_active.png")
      ],
      treeStatus: true,
      isTestVer:
        window.serverConfig.VUE_APP_COPYCONFIG_BASEURL === document.domain,
    };
  },
  computed: {
    ...mapState(["isRefreshPages", "pageDetail", "loading"]),
    lock() {
      return this.loading;
    },
    allCustomPages() {
      const root = this.customTree.data || []
      if (root.length) {
        return this.deepSearchPages(root[0]._children || [])
      }
      return []
    },
    allSystemPages() {
      const root = this.systemTree.data || []
      if (root.length) {
        return this.deepSearchPages(root[0]._children || [])
      }
      return []
    }
  },
  watch: {
    isRefreshPages(val) {
      if (val) {
        this.getPages();
      }
    },
    showId(val) {
      if (val === -1) {
        this.setHover();
      }
    },
    loading(newVal) {
      if (newVal === false && this.keyword) {
        this.keyword = ''
        // 跳转到选中的id页面
        document.getElementById(this.viewId)?.scrollIntoView({block: 'center'})
        // document.getElementById(this.viewId)?.scrollIntoView()
      }
    }
  },
  created() {
    this.$bus.on("allowGetPageDetail", () => {
      this.getPages(true);
    });
  },
  methods: {
    deepSearchPages(arr, result = []) {
      /* 结束标志 */
      if (!arr || !arr.length) return result

      /* 递归 */
      arr.forEach(r => {
        result.push(r)
        result = this.deepSearchPages(r._children, result)
      })
      return result
    },
    // 搜索页面选择
    handleSearch(e) {
      console.log(e)
      const splitArr = e.split('#')
      let type = splitArr[0]
      let value = splitArr[1]
      let params = {
        id: type === 'id' ? value : 0,
      }
      if (type === 's') params.system_page = value
      this.$emit('switch-page', params)
      this.viewId = value
    },
    generatePageThumbnail(data) {
      this.markLastId(-1);
      this.$store.commit("appDesign/setLoading", true);
      const node = document.getElementById("capture");
      toBlob(node, { cacheBust: true, })
        .then((url) => {
          /* 新标签页打开预览，右键保存 */
          window.open(URL.createObjectURL(url), "_blank");
          this.captureLoading = false;
          let fileName = '截图' + new Date().getTime() + '.png';
          filesUpload({
            configApi: "/admin/admin/media/requestUpload",
            data: new window.File([url], fileName, {type: "image/png"}),
          }).then((res) => {
            console.log(res.data.url)
            data.cover = res.data.url
            setCover({
              project_id: this.$route.params.id,
              pageset: this.$route.query.pageset,
              id: data.id,
              cover: res.data.url
            }).then(res => {
              this.$message.success(res.msg)
              this.$store.commit("appDesign/setLoading", false);
            }).catch(err => {
              console.log(err)
              this.$store.commit("appDesign/setLoading", false);
            })
          }).catch(err => {
            console.log(err)
            this.$store.commit("appDesign/setLoading", false);
          })
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("appDesign/setLoading", false);
        });
    },
    showPageThumbnail(data) {
      this.markLastId(-1);
      if (data.cover) {
        this.$previewImage([data.cover])
      }
    },
    // 改变节点的状态
    changeTreeNodeStatus(node) {
      node.expanded = this.treeStatus;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.treeStatus;
        // 看看他孩子的长度，有的话就调用自己往下找
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeTreeNodeStatus(node.childNodes[i]);
        }
      }
    },
    /* 点击一级节点展开/收起 */
    handleClickFirst(data, node) {
      if (node.level === 1) {
        this.treeStatus = !this.treeStatus;
        this.changeTreeNodeStatus(node);
      }
    },
    markLastId(id) {
      this.setHover();
      if (this.showId !== -1) {
        this.$refs[`popover-${this.showId}`].doClose();
      }
      this.showId = id;
      this.$nextTick(() => {
        this.setHover(1);
      });
    },
    setHover(isHover) {
      let node = document.querySelector(".custom-tree-node.hover");
      if (isHover) {
        node?.parentNode?.classList.add("hover");
        return;
      }
      node?.parentNode?.classList.remove("hover");
    },
    /**
     * @params init 是否自动切换页面
     * */
    getPages(init) {
      // this.$store.commit("appDesign/setLoading", true);
      this.leftLoading = true;
      getPageList({
        platform: this.$route.params.platform,
        ...this.postData
      }).then((res) => {
          this.customTree.data = [];
          this.systemTree.data = [];
          const customPageList = res.data.custom_pages || []
          this.customTree.data.push({
            name: "自定义页面",
            _children: customPageList,
          });

          const indexPageList = customPageList.filter(el => !!el.is_index)
          this.$store.commit('appDesign/setStateAttr', [{
            key: 'isUseCustomIndex',
            val: !!indexPageList.length
          }, {
            key: 'customIndexPageName',
            val: indexPageList.length ? indexPageList[0].name : ''
          }])

          this.systemTree.data.push({
            name: "系统页面",
            _children: res.data.system_pages || [],
          });

          this.$store.commit("appDesign/setIsRefreshPages", false);
          this.leftLoading = false;

          if (init) {
            // 如果链接上带了参数，使用那个参数
            let id = this.$route.query.id;
            let system_page = this.$route.query.system_page;
            if (id) {
              this.$emit("switch-page", { id: id }, { init: true });
            } else if (system_page) {
              // /* 过滤掉 系统设置 */
              // let isSwitchPageNotSetting = true // 是切换页面，而不是系统设置
              // let selectedName = '' // 前端系统页标识
              // let allSettings = Object.keys(library.setting) // 前端所有系统页标识数组
              // allSettings.forEach(s => {
              //   if (system_page === library.setting[s].system_page) {
              //     isSwitchPageNotSetting = false
              //     selectedName = library.setting[s].selectedName
              //   }
              // })
              let switchParams = { system_page: system_page, id: 0 }
              // if (!isSwitchPageNotSetting) switchParams.selectedName = selectedName
              this.$emit("switch-page", switchParams, { init: true });
            } else if (this.customTree.data[0]._children?.length) {
              this.$emit("switch-page", { id: this.customTree.data[0]._children[0].id }, { init: true });
            } else {
              /* 没有自定义页面的数据 */
              this.$store.commit("appDesign/setLoading", false);
            }
          }
        })
        .catch((err) => {
          this.leftLoading = false;
        });
    },
    handleTreeNodeClick(data, node) {
      if (node.level > 1) {
        let params = {
          id: data.id || 0,
        };
        if (data.system_page) {
          params.system_page = data.system_page;
        }
        this.$emit("switch-page", params);
        this.markLastId(-1);
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 1) {
        return type === "inner";
      }
      return true;
    },
    allowDrag(draggingNode) {
      return draggingNode.level > 1;
    },
    handleDragStart(node, ev) {
      var img = new Image(10, 10);
      img.src = require("../../assets/images/left/page.png");
      // 不起作用
      // img.style.opacity = '0';
      ev.dataTransfer.setDragImage(img, 0, 0);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      if (this.leftLoading) return;
      // console.log("tree drag end: ", dropNode && dropNode.label, dropType);
      let pid = "";
      let ch = [];
      let currentIndex = -1;

      if (!dropNode) return
      if (dropType === "before" || dropType === "after") {
        /** 移到一级页面前后，他也是一级页面，pid为"" */
        pid = dropNode.level === 2 ? "" : dropNode.data.pid;

        if (dropNode.level === 2) {
          ch = this.customTree.data[0]._children;
          currentIndex = ch.findIndex((tr) => tr.id === draggingNode.data.id);
        } else {
          ch = this.$refs["customTree"].getNode(dropNode.data.pid).childNodes;
          currentIndex = ch.findIndex(
            (node) => node.data.id === draggingNode.data.id
          );
        }
      } else if (dropType === "inner") {
        /* 移入 */
        pid = dropNode.data.id;
        ch = dropNode.childNodes;
        currentIndex = ch.findIndex(
          (node) => node.data.id === draggingNode.data.id
        );
      } else if (dropType === "none") {
        return;
      }

      console.log("sort", currentIndex);

      this.leftLoading = true;
      updateLevelAndSort({
        id: draggingNode.data.id,
        pid: pid,
        sort: currentIndex,
        ...this.postData
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.getPages();
          this.leftLoading = false;
        })
        .catch((err) => {
          this.leftLoading = false;
        });
    },
    delPage(data) {
      if (this.leftLoading) return;
      this.markLastId(-1);
      let cancelQuestion = "确定删除这个页面？";
      if (data._children && data._children.length > 0) {
        // 该页面有子级
        cancelQuestion = "是否删除该页面及所有下属页面？";
      }
      this.$confirm(cancelQuestion, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.leftLoading = true;
          deletePage({
            id: data.id,
            ...this.postData
          })
            .then((res) => {
              this.$message.success(res.msg);
              this.$refs["customTree"].remove(data);
              if (data.id === this.pageDetail.id) {
                this.$store.commit("appDesign/setPageDetail", null);
                this.$store.commit('appDesign/setBackupJson', '')
                this.$emit("reset-selected");
              }
              this.leftLoading = false;
            })
            .catch((err) => {
              this.leftLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 复制页面配置
    copyPageDetail() {
      this.markLastId(-1);
      this.$emit("copy-page-detail");
    },
    copyPageID(id) {
      this.markLastId(-1);
      this.$emit("copy-page-id", id);
    },
    newPage(pid, label, id, beforeOrAfter) {
      if (this.loading || this.leftLoading) return;
      /* 收起操作菜单 */
      this.markLastId(-1);
      /* 排序 */
      let mySort = 0;

      /* 当前操作节点的兄弟姐妹们，包括自己在内 */
      let children = []
      const isLevelOne = pid === undefined || pid === null
      if (isLevelOne) {
        children = this.customTree.data[0]._children || []
      } else {
        children = this.$refs["customTree"].getNode(pid).childNodes || []
      }
      let len = children ? children.length : 0
      /* 被操作的对象所在下标 */
      let sourceIndex = children.findIndex((item) => {
        if (isLevelOne) {
          return item.id === id
        } else {
          return item.data.id === id
        }
      });

      /**
       * 上方或下方添加 或者 复制（相当于下方添加）
       * */
      if (id) {
        /** 根据上方还是下方决定序号 */
        if (beforeOrAfter === 2) {
          mySort = sourceIndex + 1
        } else {
          mySort = sourceIndex
        }
      } else {
        /**
         * 添加子级
         * */
        mySort = len;
      }

      let pageName = "";
      if (label) {
        if (label.lastIndexOf("副本") === label.length - 2) {
          /* 已有副本字眼就不再重复追加 */
          pageName = label;
        } else {
          pageName = `${label}副本`;
        }
      } else {
        pageName = `第${len + 1}页`;
      }
      let params = {
        name: pageName,
        pid: pid || "",
        sort: mySort,
      };
      // console.log('新序号', mySort)
      this.$emit("switch-page", {}, { newPage: params });
    },
    setIndex(data) {
      this.markLastId(-1)
      setIndex({
        ...this.postData,
        id: data.id,
        is_index: data.is_index ? 0 : 1
      }).then(res => {
        this.$message.success(res.msg)
        this.$store.commit("appDesign/setIsRefreshPages", true);
      }).catch(err => {
        console.log(err)
      })
    }
  },
};
</script>

<style lang="scss">
.page-manager {
  .el-tree {
    background-color: unset;

  /*  .el-tree-node__content:hover {
      .label {
        color: $primary;
      }
    }*/

    &:hover {
      .custom-tree-node.hover {
      }
    }

    .el-tree__drop-indicator {
      height: 2px;

      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 2px solid $primary;
        position: absolute;
        left: -5px;
        top: -2px;
      }
    }
  }

  .el-tree-node {
    &:focus {
      .el-tree-node__content {
        background-color: unset;
      }
    }

    .el-tree-node__content {
      height: 36px;

      &:hover,
      &.hover {
        background-color: #e9f1fd;

        .icon {
          display: block;
        }
      }
    }

    &.is-drop-inner {
      /*background-color: #e9f1fd;*/
      outline: 2px solid $primary;
      outline-offset: -2px;
    }
  }

  .custom-tree-node {
    width: 100%;
    line-height: 3;
    font-size: 12px;

    padding-right: 30px;
    position: relative;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.active {
      .label {
        color: $primary;
      }

      .icon-more {
        color: $primary;
      }
    }

    .page-title {
      display: flex;
      align-items: center;

      .label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      img {
        width: 12px;
        min-width: 12px;
        margin-right: 4px;
      }
    }

    .icon {
      position: absolute;
    }

    .icon-plus {
      top: -1px;
      right: 10px;
      font-size: 16px;
      line-height: 2.2;
    }

    .icon-more {
      top: 1px;
      right: 12px;
      font-size: 12px;
      display: none;
    }
  }

  .rename-input {
    .el-input__inner {
      line-height: 18px;
      height: 18px;
    }
  }
}

.page-manager-popover {
  padding: 0;
  margin-top: -7px;

  .pop-item {
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    color: #3a3a3a;
    cursor: pointer;

    &:hover {
      background-color: rgba(216, 216, 216, 0.3);
    }

    &[disabled] {
      color: #c0c4cc;
      cursor: not-allowed;

      &:hover {
        background-color: unset;
      }
    }
  }
}

  .el-icon-more {
    font-size: 14px;
  }

  .page-search-bar {
    position: sticky;
    top: 0;
  }

  .search-select {
    width: 100%;
    border-bottom: .5px solid $border-color;

    .el-input__inner {
      border-color: transparent !important;
    }
  }
</style>
