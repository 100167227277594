var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
      padding: ("12px " + (_vm.myStyleType === 'template3'?'16':'0') + "px"),
      margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
      backgroundColor: _vm.myConfig.normal.bgType === 'color'?_vm.myConfig.normal.bgColor:'',
      backgroundImage: _vm.myBgImage
  })},[_c('StListTitle',{style:({
    padding: ("0 " + (_vm.myStyleType !== 'template3'?'16':'0') + "px 16px")
  }),attrs:{"config":_vm.myConfig}}),(['template1','template2'].includes(_vm.myStyleType))?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"data-list",class:_vm.myStyleType === 'template1'?'flex-column':'grid-two-column',style:(_vm.myStyleType === 'template2'?("row-gap:" + (_vm.myTemplateData.dataGap) + "px;column-gap:" + (_vm.myTemplateData.dataGap) + "px;"):'')},_vm._l((_vm.onlineLearning_list),function(item,index){return _c('div',{key:item.id,staticClass:"list-item",class:_vm.myStyleType === 'template1' && _vm.myTemplateData.dataSpacing ===0?'no-data-spacing':'',style:({
      marginTop: _vm.myStyleType === 'template1' && index > 0?_vm.myTemplateData.dataSpacing + 'px':'',
      borderRadius: _vm.myStyleType === 'template1' && _vm.myTemplateData.dataSpacing !==0? _vm.myTemplateData.borderRadius + 'px' : _vm.myStyleType === 'template1' && ![0,_vm.onlineLearning_list.length-1].includes(index)?'0px': _vm.myTemplateData.borderRadius + 'px'
    })},[(_vm.myStyleType === 'template1')?[_c('div',{staticClass:"template1"},[_c('div',{staticClass:"item-image-box",style:({
            borderRadius: _vm.myTemplateData.borderRadius + 'px',
          })},[_c('st-load-image',{staticClass:"item-img",attrs:{"src":item.cover,"size":300}})],1),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-tag"},[_vm._v(_vm._s(item.category_name))]),_c('div',{staticClass:"content-bottom"},[_c('div',{staticClass:"bottom-left"},[_vm._v("共 "+_vm._s(item.chapters_count)+" 章节 "+_vm._s(item.courses_count)+" 节课")]),_c('div',{staticClass:"bottom-right"},[_c('img',{staticClass:"right-icon",attrs:{"src":require("../../assets/images/st-online-learning/view-icon.png")}}),_c('div',{staticClass:"right-text"},[_vm._v(_vm._s(item.view_count))])])])])])]:_vm._e(),(_vm.myStyleType === 'template2')?[_c('div',{staticClass:"template2"},[_c('div',{staticClass:"item-image-box",style:({
            borderRadius: _vm.myTemplateData.borderRadius + 'px',
          })},[_c('st-load-image',{staticClass:"item-img",attrs:{"src":item.cover,"size":300}})],1),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-tag"},[_vm._v(_vm._s(item.category_name))]),_c('div',{staticClass:"content-bottom"},[_c('div',{staticClass:"bottom-top"},[_vm._v("共 "+_vm._s(item.chapters_count)+" 章节 "+_vm._s(item.courses_count)+" 节课")]),_c('div',{staticClass:"bottom-bottom"},[_c('img',{staticClass:"right-icon",attrs:{"src":require("../../assets/images/st-online-learning/view-icon.png")}}),_c('div',{staticClass:"right-text"},[_vm._v(_vm._s(item.view_count))])])])])])]:_vm._e()],2)}),0):_vm._e(),(_vm.myLoadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myLoadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myLoadingConfig.handleText))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }