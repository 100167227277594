import { randomString } from '@/base/utils/tool'
import StListTitle from '@/modules/app-design/components/Preview/Common/StListTitle'

export default {
  components: { StListTitle },
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      /* 组件内部随机 ID */
      randomId: randomString(8),
      defaultIcon: require('../assets/images/default-icon.png'),
      defaultIconBgColor: '#f1f3f6',
      // defaultIcon: require('../assets/images/mini-default-icon@2x.png'),
      model: null,
    }
  },
  computed: {
    myConfig() {
      if (!this.model) return {}
      return this.$mergeModel(this.model, this.config)
    },
    myTemplateData() {
      return this.myConfig?.templateData || {}
    },
    myStyleType() {
      return this.myConfig?.templateData?.styleType || 'template1'
    },
    myListTitle() {
      return this.myConfig?.ListTitle || {}
    },
    myBgImage() {
      const myConfig = this.myConfig
      if (myConfig.normal?.bgType === 'image' && myConfig.normal?.bgImage) {
        return `url(${this.fixSpecialImageUrl(
          myConfig.normal.bgImage,
          'large'
        )})`
      }
      return ''
    },
    myRootStyle() {
      const rS = {}
      const myConfig = this.myConfig
      if (myConfig.normal?.bgType) {
        rS.backgroundColor =
          myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : ''
        rS.backgroundImage = this.myBgImage
      }
      // 圆角
      if (myConfig.normal) {
        rS.borderRadius = myConfig.normal.borderRadius ? myConfig.normal.borderRadius + 'px' : 0 + 'px'
      }
      if (myConfig.normal) {
        rS.margin = `${myConfig.normal.marginTop}px ${
          myConfig.normal.rowMargin || 0
        }px 0`
        if (myConfig.normal.rowMargin) {
          rS['--row-margin'] = myConfig.normal.rowMargin + 'px'
        }
      }
      if (myConfig.templateData?.dataSpacing) {
        rS['--data-spacing'] = myConfig.templateData.dataSpacing + 'px'
      }
      return rS
    },
    myLoadingConfig() {
      return this.myConfig?.loadingConfig || {}
    },
  },
  methods: {
    /**
     * 封装的页面装修图片裁剪
     * @param src
     * @param sizeStr   small|medium|large|200
     * @param others
     * @returns {*|string}
     */
    fixSpecialImageUrl(src, sizeStr, ...others) {
      if (!src) {
        return this.defaultIcon
      }
      let str = others ? others.join('_') : ''
      const isPageset = this.$route.query.pageset
      return this.fixImageUrl(
        src,
        {
          w:
            sizeStr === 'small'
              ? 200
              : sizeStr === 'medium'
              ? 300
              : sizeStr === 'large'
              ? 500
              : sizeStr || 200,
        },
        isPageset ? `${this.randomId}_${str}_s` : ''
      )
    },
  },
}
