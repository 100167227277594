var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.myConfig.normal.isShow)?_c('div',{staticClass:"container st-search",style:({
    marginTop: _vm.myConfig.normal.marginTop + 'px',
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage:
      _vm.myConfig.normal.bgType === 'image'
        ? ("url(" + (_vm.fixImageUrl(_vm.myConfig.normal.bgImage, { h: 500 }, true)) + ")")
        : '',
    borderRadius: _vm.myConfig.normal.borderRadius + 'px',
  })},[_c('div',{staticClass:"search",style:({
      borderRadius:
        _vm.myConfig.normal.shapeType === 'circle'
          ? _vm.myConfig.normal.height + 'px'
          : '6px',
      justifyContent:
        _vm.myConfig.normal.textPosition === 'center' ? 'center' : '',
      height: _vm.myConfig.normal.height + 'px',
      backgroundColor: _vm.myConfig.normal.searchColor,
    })},[_c('div',{staticClass:"search-box"},[_c('p',{staticClass:"search-icon el-icon-search",style:({
          color: _vm.myConfig.normal.placeholderColor,
        })}),(_vm.myConfig.normal.placeholderConfig.isShow)?_c('p',{staticClass:"search-placeholder",style:({
          color: _vm.myConfig.normal.placeholderColor,
        })},[_vm._v(" "+_vm._s(_vm.myConfig.normal.placeholder ? _vm.myConfig.normal.placeholder : _vm.myConfig.normal.placeholderConfig.name || '请输入搜索内容')+" ")]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }