import StDonationList from '@/modules/app-design/components/Preview/StDonationList'
import StInvitationPage from '@/modules/app-design/components/Preview/StInvitationPage'
import StShopCategoryPage from '@/modules/app-design/components/Preview/st-shop-category-page/StShopCategoryPage'
import StMembershipDynamics from '@/modules/app-design/components/Preview/StMembershipDynamics'
import StVideo from '@/modules/app-design/components/Preview/StVideo'
import StImage from '@/modules/app-design/components/Preview/StImage'
import StBottomNav from '@/modules/app-design/components/Preview/StBottomNav'
import StImageTextNav from '@/modules/app-design/components/Preview/StImageTextNav'
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
import StCustomHeader from '@/modules/app-design/components/Preview/st-custom-header/StCustomHeader'
import StCustomNav from '@/modules/app-design/components/Preview/StCustomNav'
import StSpiritList from '@/modules/app-design/components/Preview/StSpiritList'
import StActivityList from '@/modules/app-design/components/Preview/StActivityList'
import StActivityListGov from '@/modules/app-design/components/Preview/StActivityListGov'
import StBottomWatermark from '@/modules/app-design/components/Preview/StBottomWatermark'
import StJoinOrganize from '@/modules/app-design/components/Preview/StJoinOrganize'
import StSource from '@/modules/app-design/components/Preview/StSource'
import StActivityImages from '@/modules/app-design/components/Preview/StActivityImages'
import StMultiLevelList from '@/modules/app-design/components/Preview/StMultiLevelList'
import StContacts from '@/modules/app-design/components/Preview/StContacts'
import StOfficialAccount from '@/modules/app-design/components/Preview/StOfficialAccount'
import StAboutMe from '@/modules/app-design/components/Preview/StAboutMe'
import StBroadcast from '@/modules/app-design/components/Preview/StBroadcast'
import StHonorCertificate from '@/modules/app-design/components/Preview/StHonorCertificate'
import StNewTrends from '@/modules/app-design/components/Preview/StNewTrends'
import StMemberMessage from '@/modules/app-design/components/Preview/StMemberMessage'
import StQuickJoin from '@/modules/app-design/components/Preview/StQuickJoin'
import StBusinessAssociationHome from '@/modules/app-design/components/Preview/StBusinessAssociationHome'
import StBusinessAssociationList from '@/modules/app-design/components/Preview/StBusinessAssociationList'
import StEnterpriseListGov from '@/modules/app-design/components/Preview/StEnterpriseListGov'
import StBusinessAssociation from '@/modules/app-design/components/Preview/StBusinessAssociation'
import StMemberIndex from '@/modules/app-design/components/Preview/StMemberIndex'
import StDemandList from '@/modules/app-design/components/Preview/StDemandList'
import StArticleLiked from '@/modules/app-design/components/Preview/StArticleLiked'
import StArticleContent from '@/modules/app-design/components/Preview/StArticleContent'
import StSpecialArticle from '@/modules/app-design/components/Preview/StSpecialArticle'
import StActivityPhoto from '@/modules/app-design/components/Preview/StActivityPhoto'
import StLiveList from '@/modules/app-design/components/Preview/StLiveList'
import StLivePlugin from '@/modules/app-design/components/Preview/StLivePlugin'
import StPosterShare from '@/modules/app-design/components/Preview/StPosterShare'
import StShare from '@/modules/app-design/components/Preview/StShare'
import StTheme from '@/modules/app-design/components/Preview/StTheme'
import StStartPage from '@/modules/app-design/components/Preview/StStartPage'
import StLoginPage from '@/modules/app-design/components/Preview/StLoginPage'
import StSearch from '@/modules/app-design/components/Preview/StSearch'
import StText from '@/modules/app-design/components/Preview/StText'
import StContactMe from '@/modules/app-design/components/Preview/StContactMe'
import StOldMePage from '@/modules/app-design/components/Preview/StOldMePage'
import StUserCenterNav from '@/modules/app-design/components/Preview/StUserCenterNav'
import StDefaultUserCenterNav from '@/modules/app-design/components/Preview/StDefaultUserCenterNav'
import StShopGoods from '@/modules/app-design/components/Preview/StShopGoods'
import StElectronCard from '@/modules/app-design/components/Preview/StElectronCard'
import StLevelSystem from '@/modules/app-design/components/Preview/StLevelSystem'
import StArticleComment from '@/modules/app-design/components/Preview/StArticleComment'
import StMailList from '@/modules/app-design/components/Preview/StMailList'
import StMailContent from '@/modules/app-design/components/Preview/StMailContent'
import StJoinOrganizeForm from '@/modules/app-design/components/Preview/StJoinOrganizeForm'
import StCompanyLibrary from '@/modules/app-design/components/Preview/StCompanyLibrary'
import StMap from '@/modules/app-design/components/Preview/StMap'
import StCompanyContact from '@/modules/app-design/components/Preview/StCompanyContact'
import StFreeContainer from '@/modules/app-design/components/Preview/StFreeContainer'
import StStructure from '@/modules/app-design/components/Preview/StStructureV2'
import StMessageFeedback from '../../components/Preview/StMessageFeeback'
import StOnlineLearning from '@/modules/app-design/components/Preview/StOnlineLearning'
import StChannelsLiveList from '@/modules/app-design/components/Preview/StChannelsLiveList'
import StChannelsVideoList from '@/modules/app-design/components/Preview/StChannelsVideoList'
import StProceedings from '@/modules/app-design/components/Preview/StProceedings'
import StArticleDetailAdvance from '@/modules/app-design/components/Preview/StArticleDetailAdvance'
import StColumnNavigation from '@/modules/app-design/components/Preview/StColumnNavigation'
import StFollowUs from '@/modules/app-design/components/Preview/StFollowUs'
import StContactUs from '@/modules/app-design/components/Preview/StContactUs'
import StCommunityNav from '@/modules/app-design/components/Preview/StCommunityNav'

const comps = {
  StStructure,

  StPageSet,
  StDonationList,
  StInvitationPage,

  // StIntegralShopIndex,

  StMembershipDynamics,
  StVideo,
  StImage,
  StBottomNav,
  StImageTextNav,
  StCustomHeader,
  StSpiritList,
  StActivityList,
  StActivityListGov,
  StBottomWatermark,
  StJoinOrganize,
  StSource,
  StActivityImages,
  StMessageFeedback,
  StMultiLevelList,
  StContacts,
  StOfficialAccount,
  StBroadcast,
  StHonorCertificate,
  StNewTrends,
  StMemberMessage,
  StQuickJoin,
  StBusinessAssociationHome,
  StBusinessAssociationList,
  StEnterpriseListGov,
  StBusinessAssociation,
  StMailList,
  StMailContent,
  StJoinOrganizeForm,
  StCompanyLibrary,
  StMap,
  StCompanyContact,
  StFreeContainer,

  StUserCenterNav,
  StDefaultUserCenterNav,
  StShopGoods,
  StElectronCard,
  StLevelSystem,
  StArticleComment,
  StDemandList,

  StSpecialArticle,
  StActivityPhoto,
  StLiveList,
  StLivePlugin,
}

export default comps

// export const loadComponents = ['StArticleList'].map((comp) => () => import(`@/modules/app-design/components/Preview/${comp}`))
export const dynamicComponents = {
  StArticleList: () =>
    import(`@/modules/app-design/components/Preview/StArticleList`),
  StActivityList: () =>
    import(`@/modules/app-design/components/Preview/StActivityList`),
  StInternetContentProvider: () =>
    import(`@/modules/app-design/components/Preview/StInternetContentProvider`),
  StCompanyIndex: () =>
    import(
      `@/modules/app-design/components/Preview/st-company-index/StCompanyIndex`
    ),
  StRelatedCompany: () =>
    import(`@/modules/app-design/components/Preview/StRelatedCompany`),
  StHotTopic: () =>
    import(`@/modules/app-design/components/Preview/StHotTopic`),
  StCircleGroup: () =>
    import(`@/modules/app-design/components/Preview/StCircleGroup`),
  StCommunityTalent: () =>
    import(`@/modules/app-design/components/Preview/StCommunityTalent`),
  StEmployCareerList: () =>
    import(`@/modules/app-design/components/Preview/StEmployCareerList`),
  StJournalList: () =>
    import(`@/modules/app-design/components/Preview/StJournalList`),
  StCommunityNavOld: () =>
    import(`@/modules/app-design/components/Preview/StCommunityNavOld`),
  StCommunitySquare: () =>
    import(`@/modules/app-design/components/Preview/StCommunitySquare`),
  StCommunityNav: () =>
    import(`@/modules/app-design/components/Preview/StCommunityNav`),
  StContactUs: () =>
    import(`@/modules/app-design/components/Preview/StContactUs`),
  StFollowUs: () =>
    import(`@/modules/app-design/components/Preview/StFollowUs`),
  StColumnNavigation: () =>
    import(`@/modules/app-design/components/Preview/StColumnNavigation`),
  StArticleDetailAdvance: () =>
    import(`@/modules/app-design/components/Preview/StArticleDetailAdvance`),
  StProceedings: () =>
    import(`@/modules/app-design/components/Preview/StProceedings`),
  StChannelsVideoList: () =>
    import(`@/modules/app-design/components/Preview/StChannelsVideoList`),
  StChannelsLiveList: () =>
    import(`@/modules/app-design/components/Preview/StChannelsLiveList`),
  StOnlineLearning: () =>
    import(`@/modules/app-design/components/Preview/StOnlineLearning`),
  StSubscribeMsg: () =>
    import(`@/modules/app-design/components/Preview/StSubscribeMsg`),
  StSchoolContactList: () =>
    import(`@/modules/app-design/components/Preview/StSchoolContactList`),
  StVenue: () => import(`@/modules/app-design/components/Preview/StVenue`),
  StServiceProvider: () =>
    import(`@/modules/app-design/components/Preview/StServiceProvider`),
  StArticleSubject: () =>
    import(`@/modules/app-design/components/Preview/StArticleSubject`),
  StInterconnectResource: () =>
    import(`@/modules/app-design/components/Preview/StInterconnectResource`),
  StChatMsg: () => import(`@/modules/app-design/components/Preview/StChatMsg`),
  StChatMsgList: () =>
    import(`@/modules/app-design/components/Preview/StChatMsgList`),
  StLink: () => import(`@/modules/app-design/components/Preview/StLink`),
  StBanner: () => import(`@/modules/app-design/components/Preview/StBanner`),
  StSearch: () => import(`@/modules/app-design/components/Preview/StSearch`),
  StText: () => import(`@/modules/app-design/components/Preview/StText`),
  StLiveList: () =>
    import(`@/modules/app-design/components/Preview/StLiveList`),
  StBrandList: () =>
    import(`@/modules/app-design/components/Preview/StBrandList`),
  StVideo: () => import(`@/modules/app-design/components/Preview/StVideo`),
  StCustomNav: () =>
    import(`@/modules/app-design/components/Preview/StCustomNav`),
  StMePage: () => import('@/modules/app-design/components/Preview/StMePage'),
  StShopCategories: () => import('@/modules/app-design/components/Preview/StShopCategories'),
  StShopIndex: () => import('@/modules/app-design/components/Preview/StShopIndex'),
}
console.log('~新增加的 业务组件/系统页 组件需要在这边导入')
