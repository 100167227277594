var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:[_vm.myStyleType],style:([_vm.myRootStyle])},[(_vm.myStyleType === 'template1')?[(_vm.myConfig.templateData.showType === 'swiper')?_c('div',[_c('swiper',{ref:"mySwiper",staticClass:"show-swiper",attrs:{"options":_vm.options}},[_vm._l((_vm.swiperData.length
            ? _vm.swiperData
            : _vm.defaultList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"row-item swiper-no-swiping",style:({
            paddingBottom: _vm.swiperData.length > 1 ? '8px' : '0',
          })},_vm._l((item),function(nav,i){return _c('div',{key:i,staticClass:"nav-item",style:({
              flex: ("0 0 " + (100 / _vm.myConfig.templateData.numLimit) + "%"),
              marginTop: i >= _vm.myConfig.templateData.numLimit ? '12px' : '',
            })},[_c('el-image',{staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(nav.icon, '', index, i),"fit":"cover"}}),_c('p',{staticClass:"nav-name",style:({
                color: _vm.myConfig.templateData.textColor,
                marginTop: _vm.myConfig.templateData.textSpacing + 'px',
              })},[_vm._v(" "+_vm._s(nav.name || ("导航" + (_vm.ch_sort[i])))+" ")])],1)}),0)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperData.length > 1),expression:"swiperData.length > 1"}],staticClass:"swiper-pagination"})],2)],1):_c('div',{staticClass:"show-fixed"},_vm._l((_vm.myConfig.tabs.list.length
          ? _vm.myConfig.tabs.list
          : _vm.defaultList),function(nav,i){return _c('div',{key:i,staticClass:"nav-item",style:({
          flex: ("0 0 " + (100 / _vm.myConfig.templateData.numLimit) + "%"),
          marginTop: i >= _vm.myConfig.templateData.numLimit ? '12px' : '',
        })},[_c('el-image',{staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(nav.icon, '', i),"fit":"cover"}}),_c('p',{staticClass:"nav-name",style:({
            color: _vm.myConfig.templateData.textColor,
            marginTop: _vm.myConfig.templateData.textSpacing + 'px',
          })},[_vm._v(" "+_vm._s(nav.name ? nav.name : ("导航" + (_vm.ch_sort[i])))+" ")])],1)}),0)]:(['template2', 'template3'].includes(_vm.myStyleType))?[_c('div',{staticClass:"x-scroll-list"},_vm._l((_vm.myConfig.tabs.list.length
          ? _vm.myConfig.tabs.list
          : _vm.defaultList),function(nav,i){return _c('div',{key:i,staticClass:"nav-item",class:_vm.myStyleType,style:({
          marginRight: ((_vm.myConfig.templateData.dataSpacing) + "px"),
          backgroundColor:
            !nav.icon && _vm.myStyleType === 'template2' ? '#F3F5F7' : '',
          borderRadius: _vm.myStyleType === 'template2' ? _vm.borderRadiusString : '',
        })},[(_vm.myStyleType === 'template2')?[_c('st-load-image',{staticClass:"image",attrs:{"src":nav.icon,"size":300}})]:_vm._e(),(_vm.myStyleType === 'template3')?[_c('el-image',{staticStyle:{"overflow":"hidden"},style:({
              borderRadius: _vm.borderRadiusString,
            }),attrs:{"src":_vm.fixSpecialImageUrl(nav.icon, '', i),"fit":"cover"}}),_c('p',{staticClass:"nav-name",style:({
              color: _vm.myConfig.templateData.textColor,
              marginTop: _vm.myConfig.templateData.textSpacing + 'px',
            })},[_vm._v(" "+_vm._s(nav.name ? nav.name : ("导航" + (_vm.ch_sort[i])))+" ")])]:_vm._e()],2)}),0)]:_vm._e(),(_vm.myStyleType === 'template4')?[_c('div',{staticClass:"template4-box"},[(_vm.myConfig.templateData.template4StyleType === 1)?_c('div',{staticClass:"template4-content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"one-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[0].icon
                    : _vm.defaultList[0].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"one-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[1].icon
                    : _vm.defaultList[1].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)])]):(_vm.myConfig.templateData.template4StyleType === 2)?_c('div',{staticClass:"template4-content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"two-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[0].icon
                    : _vm.defaultList[0].icon,
                  '',
                  1
                ),"fit":"cover"}}),_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[1].icon
                    : _vm.defaultList[1].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"two-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[2].icon
                    : _vm.defaultList[2].icon,
                  '',
                  1
                ),"fit":"cover"}}),_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[3].icon
                    : _vm.defaultList[3].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)])]):(_vm.myConfig.templateData.template4StyleType === 3)?_c('div',{staticClass:"template4-content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"one-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[0].icon
                    : _vm.defaultList[0].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"two-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[1].icon
                    : _vm.defaultList[1].icon,
                  '',
                  1
                ),"fit":"cover"}}),_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[2].icon
                    : _vm.defaultList[2].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)])]):(_vm.myConfig.templateData.template4StyleType === 4)?_c('div',{staticClass:"template4-content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"two-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[0].icon
                    : _vm.defaultList[0].icon,
                  '',
                  1
                ),"fit":"cover"}}),_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[1].icon
                    : _vm.defaultList[1].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"one-template"},[_c('el-image',{staticClass:"cover",staticStyle:{"overflow":"hidden"},style:({ borderRadius: _vm.borderRadiusString }),attrs:{"src":_vm.fixSpecialImageUrl(
                  _vm.myConfig.tabs.list.length
                    ? _vm.myConfig.tabs.list[2].icon
                    : _vm.defaultList[2].icon,
                  '',
                  1
                ),"fit":"cover"}})],1)])]):_vm._e(),(
          _vm.myConfig.tabs.list.length >
          _vm.getDotLength(_vm.myConfig.templateData.template4StyleType)
        )?_c('div',{staticClass:"swiper-dots"},[_c('div',{staticClass:"dots-list"},_vm._l((Math.ceil(
              _vm.myConfig.tabs.list.length /
                _vm.getDotLength(_vm.myConfig.templateData.template4StyleType)
            )),function(i){return _c('div',{key:i,class:['dot', i === 1 ? 'active' : '']})}),0)]):_vm._e()])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }