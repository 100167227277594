<template>
  <MiniProgramViewer class="start-left" :class="[myConfig.normal.showType]" :footer-image="myConfig.normal.showType !== 'default'?'':myConfig.normal.bottom" :is-show-left="false"
    :footer-text="myConfig.normal.copyright" :isShowFooter="myConfig.normal.isShow === 1" :status-bar-color="myConfig.normal.showType !== 'default'?'white':'black'">
    <div v-show="myConfig.normal.isShow">
      <template v-if="myConfig.normal.showType && myConfig.normal.showType !== 'default'">
        <img v-if="!!(myConfig.normal.showType==='image'? myConfig.normal.image:myConfig.normal.video.thumbnail)" class="full-img"
             :class="[myConfig.normal.showType]" :src="myConfig.normal.showType==='image'? myConfig.normal.image:myConfig.normal.video.thumbnail" />
        <div v-else class="full-img" :class="[myConfig.normal.showType]" />
      </template>
      <template v-else>
        <div class="img-swiper" :class="myConfig.normal.images.length > 0?'':'empty-image'">
          <slider ref="slider" :options="options" v-if="myConfig.normal.images.length > 0">
            <slideritem v-for="(item,index) in myConfig.normal.images" :key="index">
              <el-image :src="item" fix="cover"/>
            </slideritem>
          </slider>
        </div>
        <el-image class="start-top-title" v-if="myConfig.normal.middle" :src="myConfig.normal.middle"
                  fit="contain">
        </el-image>
        <div class="start-top-title none" v-if="!myConfig.normal.middle"></div>
      </template>
    </div>
  </MiniProgramViewer>
</template>

<script>
import MiniProgramViewer from "@/base/components/Preview/MiniProgramViewer";
import { slider, slideritem } from "vue-concise-slider";
export default {
  name: "st-start-page",
  components: { MiniProgramViewer, slider, slideritem },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        normal: {
          isShow: 0,
          images: [""],
          bottom: "",
          copyright: "",
          middle: "",
          "video": {
            "url": "",
            "thumbnail": "",
            "type": "video"
          },
          image: "",
          showType: 'default',
          duration: 3,
          jump_link: null
        },
      },
      options: {
        thresholdDistance: 30,
        loop: false,
        pagination: false,
        itemAnimation: true,
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.start-left {
  &.image {
    ::v-deep {
      .mini-program-nav-bar {
        background-color: unset;
        /*z-index: 0;*/
        display: none;
      }
    }
  }

  &.video {
    ::v-deep {
      .mini-program-nav-bar {
        background-color: unset;
        display: none;
      }

      .mini-program-head {
        z-index: 2;
      }
    }
  }

  .img-swiper {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 172px;
  }

  .empty-image {
    background-color: $image-placeholder;
    // border: 1px solid #dcdfe6;
  }

  .start-top-title {
    width: 288px;
    height: 19px;
    position: absolute;
    top: 296px;
    left: 44px;

    &.none {
      background: #f5f7fa;
      // border: 1px solid #dcdfe6;
    }
  }

  .full-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    object-fit: cover;
    z-index: 1;

    &.image {
      z-index: 0;
    }
  }
}
</style>
