<!--
 * @Author: dongjia
 * @Date: 2021-12-30 09:42:30
 * @LastEditTime: 2022-03-02 18:01:13
 * @LastEditors: aleaner
 * @Description: 活动列表内嵌图文组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-activity\activity-list-img-text.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="timeline" :style="{
    borderRadius: borderRadius + 'px'
  }">
    <div class="timeline-header">
      <el-image class="timeline-avatar"
        :src="timelineData.user_avatar?fixImageUrl(timelineData.user_avatar, {}, true):default_avatar" fit="cover">
      </el-image>
      <span class="timeline-name">{{timelineData.user_name}}</span>
      <span class="timeline-time">{{timelineData.create_time}}</span>
    </div>
    <div class="timeline-content" v-if="timelineData.content">{{timelineData.content}}</div>
    <div class="timeline-medias" v-if="timelineData.res.length">
      <div class="media-item" v-for="(item, index) in timelineData.res" :style="{
        backgroundColor: item.url?'':'#F3F5F7'
      }">
        <el-image v-if="(item.url && item.type === 'image')||(item.thumbnail && item.type === 'video')"
          class="timeline-avatar" :src="fixImageUrl(item.type === 'image'?item.url:item.thumbnail, {}, true)"
          fit="cover"></el-image>
        <img v-if="!item.url && item.type === 'image'" class="default-icon"
          src="../../../assets/images/mini-default-icon@2x.png" />
        <img class="video-play" v-if="item.type==='video'" src="../../../assets/images/video-play.png" />
        <div v-if="timelineData.res_count > 3 && index === 2" class="img-mask">
          + {{timelineData.res_count - 3}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timelineData: {
      type: Object,
      required: true,
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      default_avatar: require("../../../assets/images/st-spirit-list/square-avatar1.png"),
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.timeline {
  padding: 8px 12px;
  background: #fafafa;
  .timeline-header {
    display: flex;
    align-items: center;
    .timeline-avatar {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-right: 8px;
    }
    .timeline-name {
      font-size: 12px;
      color: #1a1a1a;
      line-height: 1.5;
    }
    .timeline-time {
      display: block;
      margin-left: auto;
      font-size: 12px;
      color: #b3b3b3;
      line-height: 1.5;
    }
  }
  .timeline-content {
    margin-top: 12px;
    font-size: 14px;
    color: #1a1a1a;
    line-height: 1.5;
  }
  .timeline-medias {
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
    .media-item {
      position: relative;
      width: 100%;
      .el-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
      .video-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
      }
      .default-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
      }
      .img-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.5;
        background: rgba(0, 0, 0, 0.7);
        z-index: 2;
      }
      &::before {
        content: "";
        display: block;
        padding-top: 77%;
      }
    }
  }
}
</style>
