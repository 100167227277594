var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-list",style:([
      _vm.myRootStyle,
      {
        padding: _vm.myStyleType === 'template4' ? '12px 0 0' : '12px 16px',
      } ])},[_c('StListTitle',{style:(_vm.myStyleType === 'template4' ? 'padding: 0 16px 16px' : ''),attrs:{"config":_vm.myConfig}}),(_vm.myStyleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-view"},_vm._l((_vm.activityList),function(item,index){return _c('div',{key:index,staticClass:"list-item template1",class:[_vm.myConfig.listStyle.shadow ? 'shadow' : '', _vm.myConfig.listStyle.border ? 'border' : ''],style:({
          marginTop: index !== 0 ? _vm.myTemplateData.dataSpacing + 'px' : '0',
          borderRadius: _vm.myTemplateData.borderRadius + 'px',
          backgroundColor: _vm.myConfig.listStyle.bgColor,
        })},[_c('div',{staticClass:"item-image-box"},[_c('st-load-image',{staticClass:"item-image",attrs:{"border-radius":8 || _vm.myTemplateData.borderRadius / 2,"size":500,"src":item.cover}}),_c('div',{staticClass:"icon-recommend"},[_c('activity-status-btn',{attrs:{"activity":item,"radius":8 || _vm.myTemplateData.borderRadius / 2,"role":"tag"}})],1)],1),_c('div',{staticClass:"item-content template1"},[_c('p',{staticClass:"card-title"},[_vm._v(_vm._s(item.name))]),(_vm.myConfig.messageConfig.showTime)?_c('div',{staticClass:"time flex-align-center"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm.timeIcon}}),_c('span',{staticClass:"clamp flex-1 min-w-0"},[_vm._v(_vm._s(item.activity_time_text_three))])]):_vm._e(),(
              item.organization_name &&
              _vm.myConfig.messageConfig.showCreator &&
              _vm.myConfig.dataConfig.dataShowType &&
              _vm.myConfig.dataConfig.dataShowType.length === 1 &&
              _vm.myConfig.dataConfig.dataShowType[0] === 'self_data'
            )?_c('div',{staticClass:"card-author"},[_vm._v(" 活动发起："+_vm._s(item.organization_name)+" ")]):(item.project_name)?_c('div',{staticClass:"card-author"},[_vm._v(" 活动发起："+_vm._s(item.project_name)+" ")]):_vm._e(),(_vm.myConfig.messageConfig.showUsers)?_c('div',{staticClass:"card-bottom flex-align-center"},[_c('div',{staticClass:"card-bottom-left"},[_c('div',{staticClass:"join-avatars"},[_c('st-avatars',{attrs:{"arr":item.activityUsers,"border-width":0.5,"indent":16,"width":32}})],1),_c('sub-activity-item-desc',{attrs:{"activity":item}})],1),_c('div',{staticClass:"card-bottom-right"},[(
                  [5].includes(item.activity_status_text_two) ||
                  (item['status_text'] ||
                    item['activity_status_text_three']) === '报名中'
                )?_c('div',{staticClass:"join-btn"},[_vm._v(" 立即报名 ")]):_c('div',{staticClass:"enter-btn flex-center"},[_c('i',{staticClass:"el-icon-arrow-right",staticStyle:{"color":"white"}})])])]):_vm._e()])])}),0):_vm._e(),(_vm.myStyleType === 'template2')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-view"},_vm._l((_vm.activityList),function(item,index){return _c('div',{key:index,staticClass:"list-item template2",class:[_vm.myConfig.listStyle.shadow ? 'shadow' : '', _vm.myConfig.listStyle.border ? 'border' : ''],style:({
          marginTop: index !== 0 ? _vm.myTemplateData.dataSpacing + 'px' : '0',
          borderRadius: _vm.myTemplateData.borderRadius + 'px',
          backgroundColor: _vm.myConfig.listStyle.bgColor,
        })},[(item.activity_status_text_two !== 4)?_c('div',{staticClass:"list-item-normal not-4"},[_c('div',{staticClass:"item-image-box"},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.cover,"size":500,"border-radius":8 || _vm.myTemplateData.borderRadius}}),_c('div',{staticClass:"icon-recommend"},[_c('activity-status-btn',{attrs:{"activity":item,"radius":8 || _vm.myTemplateData.borderRadius / 2,"role":"tag"}})],1)],1),_c('div',{staticClass:"item-content template2"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),(
                _vm.myConfig.messageConfig.showTime
              )?_c('div',{staticClass:"item-text"},[_c('div',{staticClass:"item-left"},[_c('div',{staticClass:"time flex-align-center"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm.timeIcon}}),_c('span',{staticClass:"clamp flex-1 min-w-0"},[_vm._v(_vm._s(item.activity_time_text_three))])])]),(false && !item.activity_users_count)?_c('activity-status-btn',{attrs:{"activity":item}}):_vm._e()],1):_vm._e(),(false && item.activity_status_text_two === 3)?_c('div',{staticClass:"activity-go-on"},[_c('div',{staticClass:"go-on-text"},[_c('img',{staticClass:"text-icon",attrs:{"src":require(("../../assets/images/st-activity-list/" + (item.is_live ? 'live-go-on' : 'activity-going-icon') + ".png"))}}),_c('activity-status-btn',{attrs:{"activity":item}})],1)]):_vm._e(),(
              item.organization_name &&
              _vm.myConfig.messageConfig.showCreator &&
              _vm.myConfig.dataConfig.dataShowType &&
              _vm.myConfig.dataConfig.dataShowType.length === 1 &&
              _vm.myConfig.dataConfig.dataShowType[0] === 'self_data'
            )?_c('div',{staticClass:"card-author"},[_vm._v(" 活动发起："+_vm._s(item.organization_name)+" ")]):(item.project_name)?_c('div',{staticClass:"card-author"},[_vm._v(" 活动发起："+_vm._s(item.project_name)+" ")]):_vm._e(),(
                _vm.myConfig.messageConfig.showUsers
              )?_c('div',{staticClass:"item-bottom template2 flex-between"},[_c('div',{staticClass:"bottom-left"},[_c('div',{staticClass:"join-avatars"},[_c('st-avatars',{attrs:{"arr":item.activityUsers,"border-width":0.5,"indent":16,"width":32}})],1),_c('sub-activity-item-desc',{attrs:{"activity":item}})],1),(false)?_c('div',{staticClass:"bottom-left-old"},[(_vm.myConfig.messageConfig.showUsers)?_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.activityUsers}})],1):_vm._e(),(_vm.myConfig.messageConfig.showUsers)?_c('div',{staticClass:"collect-content"},[_c('span',{staticStyle:{"margin-right":"6px"}},[_vm._v(_vm._s(item.activity_users_count > 99 ? '99+' : item.activity_users_count))]),_vm._v("人报名 ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"card-bottom-right"},[(
                  [5].includes(item.activity_status_text_two) ||
                  (item['status_text'] ||
                    item['activity_status_text_three']) === '报名中'
                )?_c('div',{staticClass:"join-btn"},[_vm._v(" 立即报名 ")]):_c('div',{staticClass:"enter-btn flex-center"},[_c('i',{staticClass:"el-icon-arrow-right",staticStyle:{"color":"white"}})])])]):_vm._e()])]):_c('div',{staticClass:"list-item-special",class:[_vm.myConfig.listStyle.imageText]},[_c('div',{staticClass:"special-item-content flex-1 flex-col flex-between",staticStyle:{"align-items":"flex-start"},style:({
            '--line-clamp': _vm.myConfig.messageConfig.showTime && _vm.myConfig.messageConfig.showUsers ? 2 : (
            _vm.myConfig.messageConfig.showTime || _vm.myConfig.messageConfig.showUsers ? '3' : 4
          )})},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"w-full flex-col"},[(_vm.myConfig.messageConfig.showUsers)?_c('div',{staticClass:"time flex-align-center",staticStyle:{"min-height":"18px"}},[(item.is_show_text)?_c('img',{staticClass:"time-icon",attrs:{"src":_vm.countIcon}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.is_show_text !== false),expression:"item.is_show_text !== false"}],staticClass:"clamp flex-1 min-w-0"},[(item)?_c('sub-activity-item-desc',{attrs:{"activity":item},on:{"text":function (t) { return (item.is_show_text = !!t); }}}):_vm._e()],1)]):_vm._e(),(_vm.myConfig.messageConfig.showTime)?_c('div',{staticClass:"time flex-align-center"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm.timeIcon}}),_c('span',{staticClass:"clamp flex-1 min-w-0"},[_vm._v(_vm._s(item.activity_time_text_three))])]):_vm._e()])]),_c('div',{staticClass:"gap",staticStyle:{"width":"12px","height":"12px","flex-shrink":"0"}}),_c('div',{staticClass:"special-item-cover item-image-box"},[_c('st-load-image',{staticClass:"cover-image",attrs:{"src":item.cover,"size":300,"border-radius":_vm.myTemplateData.borderRadius}}),_c('div',{staticClass:"icon-recommend"},[_c('activity-status-btn',{attrs:{"activity":item,"radius":8 || _vm.myTemplateData.borderRadius / 2,"role":"tag"}})],1)],1)])])}),0):_vm._e(),(_vm.myStyleType === 'template3')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-view grid-row-column",style:({
        rowGap: _vm.myTemplateData.dataSpacing + 'px',
        columnGap: _vm.myTemplateData.dataSpacing + 'px',
      })},_vm._l((_vm.activityList),function(item,index){return _c('div',{key:index,staticClass:"list-item template3 flex-col",class:[_vm.myConfig.listStyle.shadow ? 'shadow' : '', _vm.myConfig.listStyle.border ? 'border' : ''],style:({
          borderRadius: _vm.myTemplateData.borderRadius + 'px',
          backgroundColor: _vm.myConfig.listStyle.bgColor,
        })},[_c('div',{staticClass:"item-cover-box"},[_c('st-load-image',{staticClass:"item-cover",attrs:{"src":item.cover,"size":300}}),_c('div',{staticClass:"status-tag"},[_c('activity-status-btn',{attrs:{"activity":item,"radius":_vm.myTemplateData.borderRadius,"role":"tag"}})],1)],1),_c('div',{staticClass:"item-content template3 flex-1 flex-col flex-between",staticStyle:{"align-items":"flex-start"}},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex-col"},[(_vm.myConfig.messageConfig.showUsers)?_c('div',{staticClass:"time flex-align-center"},[(item.is_show_text)?_c('img',{staticClass:"time-icon",attrs:{"src":_vm.countIcon}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.is_show_text !== false),expression:"item.is_show_text !== false"}],staticClass:"clamp flex-1 min-w-0"},[_c('sub-activity-item-desc',{attrs:{"activity":item},on:{"text":function (t) { return (item.is_show_text = !!t); }}})],1)]):_vm._e(),(_vm.myConfig.messageConfig.showTime)?_c('div',{staticClass:"time flex-align-center"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm.timeIcon}}),_c('span',{staticClass:"clamp flex-1 min-w-0"},[_vm._v(_vm._s(item.activity_time_text_three))])]):_vm._e()])])])}),0):_vm._e(),(_vm.myStyleType === 'template4')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-view scroll"},_vm._l((_vm.activityList),function(item,index){return _c('div',{key:index,staticClass:"list-item flex-align-center template4",class:[
          _vm.myConfig.listStyle.imageText,
          _vm.myConfig.listStyle.shadow ? 'shadow' : '',
          _vm.myConfig.listStyle.border ? 'border' : ''
        ],style:({
          marginRight: _vm.myTemplateData.dataSpacing + 'px',
          borderRadius: _vm.myTemplateData.borderRadius + 'px',
          backgroundColor: _vm.myConfig.listStyle.bgColor,
        })},[_c('div',{staticClass:"item-cover-box"},[_c('st-load-image',{staticClass:"item-cover",attrs:{"src":item.cover,"size":300,"border-radius":8 || _vm.myTemplateData.borderRadius}}),_c('div',{staticClass:"status-tag"},[_c('activity-status-btn',{attrs:{"activity":item,"radius":8,"role":"tag"}})],1)],1),_c('div',{staticClass:"item-content template4"},[_c('div',{staticClass:"content-float-right flex-col flex-between",staticStyle:{"align-items":"flex-start"},style:({
            '--line-clamp': _vm.myConfig.messageConfig.showTime && _vm.myConfig.messageConfig.showUsers ? 2 : (
            _vm.myConfig.messageConfig.showTime || _vm.myConfig.messageConfig.showUsers ? '3' : 4
          )})},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex-col"},[(_vm.myConfig.messageConfig.showUsers)?_c('div',{staticClass:"time flex-align-center",staticStyle:{"min-height":"18px"}},[(item.is_show_text)?_c('img',{staticClass:"time-icon",attrs:{"src":_vm.countIcon}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.is_show_text !== false),expression:"item.is_show_text !== false"}],staticClass:"clamp flex-1 min-w-0"},[(item)?_c('sub-activity-item-desc',{attrs:{"activity":item},on:{"text":function (t) { return (item.is_show_text = !!t); }}}):_vm._e()],1)]):_vm._e(),(_vm.myConfig.messageConfig.showTime)?_c('div',{staticClass:"time flex-align-center"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm.timeIcon}}),_c('span',{staticClass:"clamp flex-1 min-w-0"},[_vm._v(_vm._s(item.activity_time_text_three))])]):_vm._e()])])])])}),0):_vm._e(),(_vm.myLoadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myLoadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myLoadingConfig.handleText)+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }