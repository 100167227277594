<template>
  <div class="st-bottom-nav">
    <div
      v-if="index === 0"
      class="tip-area"
      :style="{
        backgroundColor: 'white',
        'min-height':
          myConfig.templateData.styleType === 'template1' ? '553px' : '603px',
      }"
    >
      <!--      <div>-->
      <!--        <p>底部导航内容区域</p>-->
      <!--        <p>在右侧设置链接页面</p>-->
      <!--      </div>-->
      <slot />
      <template v-for="(item, index) in tabList">
        <template v-if="item.jump_link">
          <template v-if="item.jump_link.jump_config">
            <page-view
              :id="
                item.jump_link.jump_config.view.system_page
                  ? 0
                  : item.jump_link.jump_config.view.id
              "
              :init="currentIndex === index"
              :jump_link="item.jump_link"
              :key="item.name"
              :system_page="item.jump_link.jump_config.view.system_page"
              v-show="currentIndex === index"
            />
          </template>
          <template v-else>
            <div
              :style="{
                backgroundColor: myConfig.normal.contentBgColor,
              }"
              class="tip-area"
            >
              <div>
                <p>内容区域</p>
                <p>在右侧设置链接页面</p>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div
            :style="{
              backgroundColor: myConfig.normal.contentBgColor,
            }"
            class="tip-area"
          >
            <div>
              <p>内容区域</p>
              <p>在右侧设置链接页面</p>
            </div>
          </div>
        </template>
      </template>
    </div>
    <div
      v-if="myConfig.templateData.styleType === 'template1'"
      class="bottom-nav fixed"
      :style="{
        backgroundColor:
          myConfig.templateData.bgType === 'color'
            ? myConfig.templateData.bgColor
            : '',
        backgroundImage:
          myConfig.templateData.bgType === 'image'
            ? `url(${myConfig.templateData.bgImage})`
            : '',
      }"
    >
      <div
        class="nav cursor-pointer"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handleActive(index)"
        :style="{
          backgroundColor:
            (value === -1 && index === 0) || index === value
              ? myConfig.templateData.selectedBgColor
              : '',
        }"
      >
        <!-- 不是纯文字 -->
        <template v-if="myConfig.templateData.contentType !== 'text'">
          <img
            v-show="value === -1 ? item.is_default : index === value"
            class="nav-logo"
            :class="[
              myConfig.templateData.contentType === 'icon' ? 'logo-only' : '',
            ]"
            :style="{
              width: myConfig.templateData.fillBg ? '100%' : '25px',
              objectFit: myConfig.templateData.fillBg ? 'cover' : 'contain',
              flex: myConfig.templateData.fillBg ? 1 : '',
            }"
            :src="
              item.icon_checked || item.icon_normal
                ? fixImageUrl(item.icon_checked || item.icon_normal, {}, true)
                : require('../../assets/images/default-icon.png')
            "
          />
          <img
            v-show="value === -1 ? !item.is_default : index !== value"
            class="nav-logo"
            :class="[
              myConfig.templateData.contentType === 'icon' ? 'logo-only' : '',
            ]"
            :style="{
              width: myConfig.templateData.fillBg ? '100%' : '25px',
              objectFit: myConfig.templateData.fillBg ? 'cover' : 'contain',
              flex: myConfig.templateData.fillBg ? 1 : '',
            }"
            :src="
              item.icon_normal
                ? fixImageUrl(item.icon_normal, {}, true)
                : require('../../assets/images/default-icon.png')
            "
          />
        </template>
        <!-- 不是纯图标 -->
        <div
          v-if="myConfig.templateData.contentType !== 'icon'"
          class="nav-text"
          :class="[
            myConfig.templateData.contentType === 'text' ? 'text-only' : '',
          ]"
          :style="{
            color:
              myConfig.templateData[
                currentIndex === index ? 'selectedColor' : 'defaultColor'
              ],
            paddingBottom: myConfig.templateData.fillBg ? '4px' : '0',
          }"
        >
          {{ item.name ? item.name : `导航${ch_sort[index]}` }}
        </div>
      </div>
    </div>
    <template v-if="myConfig.templateData.styleType === 'template2'">
      <!-- 模板二 -->
      <div class="template2 fixed-center">
        <div
          class="nav"
          v-for="(item, index) in tabList"
          :key="item.name"
          :class="[
            index === value || (value === -1 && item.is_default)
              ? 'active'
              : '',
          ]"
          @click="handleActive(index)"
        >
          <div
            class="template2-mover"
            :style="{
              backgroundColor: myConfig.templateData.selectedBgColor,
            }"
          />
          <img
            v-if="item.icon_checked || item.icon_normal"
            v-show="index === value || (value === -1 && item.is_default)"
            class="nav-logo a"
            :src="item.icon_checked || item.icon_normal"
            mode="aspectFit"
          />
          <img
            v-if="!item.icon_checked && !item.icon_normal"
            v-show="index === value || (value === -1 && item.is_default)"
            class="nav-logo a1"
            :src="require('../../assets/images/default-icon.png')"
          />
          <img
            v-if="item.icon_normal"
            v-show="
              (index !== value && value !== -1) ||
              (value === -1 && !item.is_default)
            "
            class="nav-logo b"
            :src="item.icon_normal"
            mode="aspectFit"
          />
          <img
            v-if="!item.icon_normal"
            v-show="
              (index !== value && value !== -1) ||
              (value === -1 && !item.is_default)
            "
            class="nav-logo b1"
            :src="require('../../assets/images/default-icon.png')"
            mode="aspectFit"
          />
        </div>
        <div
          class="template2-bg"
          :style="{
            backgroundColor:
              myConfig.templateData.bgType === 'color'
                ? myConfig.templateData.bgColor
                : '',
            backgroundImage:
              myConfig.templateData.bgType === 'image'
                ? `url(${myConfig.templateData.bgImage})`
                : '',
            filter: `blur(${myConfig.templateData.blur}px)`,
            opacity: myConfig.templateData.opacity,
            height: `calc(61px + 2 * ${myConfig.templateData.blur}px)`,
            width: `calc(100% + 2 * ${myConfig.templateData.blur}px)`,
            top: `-${myConfig.templateData.blur}px`,
            left: `-${myConfig.templateData.blur}px`,
          }"
        />
      </div>
    </template>
    <div
      v-if="myConfig.templateData.styleType === 'template3'"
      class="bottom-nav fixed"
      :style="{
        backgroundColor:
          myConfig.templateData.bgType === 'color'
            ? myConfig.templateData.bgColor
            : '',
        backgroundImage:
          myConfig.templateData.bgType === 'image'
            ? `url(${myConfig.templateData.bgImage})`
            : '',
      }"
    >
      <div
        class="nav"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handleActive(index)"
        :style="{
          backgroundColor:
            (value === -1 && index === 0) || index === value
              ? myConfig.templateData.selectedBgColor
              : '',
        }"
      >
        <!-- 中间大图标 -->
        <template
          v-if="
            (tabList.length === 5 && index == 2) ||
            (tabList.length === 3 && index == 1) ||
            (tabList.length === 1 && index == 0)
          "
        >
          <div class="active">
            <img
              v-show="value === -1 ? item.is_default : index === value"
              class="big-nav-logo"
              :src="
                item.icon_checked || item.icon_normal
                  ? fixImageUrl(item.icon_checked || item.icon_normal, {}, true)
                  : require('../../assets/images/default-icon.png')
              "
            />
            <img
              v-show="value === -1 ? !item.is_default : index !== value"
              class="big-nav-logo"
              :src="
                item.icon_normal
                  ? fixImageUrl(item.icon_normal, {}, true)
                  : require('../../assets/images/default-icon.png')
              "
            />
            <!-- 文字 -->
            <div
              class="nav-text"
              :style="{
                color:
                  myConfig.templateData[
                    (value === -1 ? item.is_default : index === value)
                      ? 'selectedColor'
                      : 'defaultColor'
                  ],
              }"
            >
              {{ item.name ? item.name : `导航${ch_sort[index]}` }}
            </div>
          </div>
        </template>
        <!-- 普通item -->
        <template v-else>
          <!-- 图片 -->
          <template>
            <!-- 高亮 -->
            <img
              v-show="value === -1 ? item.is_default : index === value"
              class="nav-logo"
              :src="
                item.icon_checked || item.icon_normal
                  ? fixImageUrl(item.icon_checked || item.icon_normal, {}, true)
                  : require('../../assets/images/default-icon.png')
              "
            />
            <!-- 不是高亮 -->
            <img
              v-show="value === -1 ? !item.is_default : index !== value"
              class="nav-logo"
              :src="
                item.icon_normal
                  ? fixImageUrl(item.icon_normal, {}, true)
                  : require('../../assets/images/default-icon.png')
              "
            />
          </template>
          <!-- 文字 -->
          <template>
            <div
              class="nav-text"
              :style="{
                color:
                  myConfig.templateData[
                    (value === -1 ? item.is_default : index === value)
                      ? 'selectedColor'
                      : 'defaultColor'
                  ],
              }"
            >
              {{ item.name ? item.name : `导航${ch_sort[index]}` }}
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import PageView from '../Page/PageView'
export default {
  name: 'st-bottom-nav',
  components: { PageView: () => import('../Page/PageView') },
  inject: {
    switchPage: {
      value: null,
      default: () => {},
    },
    toggleJoinFloat: {
      value: null,
      default: () => {},
    },
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      default: -1,
    },
    updateValue: {
      type: Function,
      default: () => {},
    },
    // 组件列表才有index传值，说明这是拖拽进来的底部导航组件
    index: Number,
  },
  data() {
    return {
      bottomNavList: require('../../assets/jsons/default-list.json').bottomNav,
      ch_sort: require('../../assets/jsons/ch-sort.json'),
      model: {
        templateData: {
          styleType: 'template1',
          bgType: 'color',
          bgColor: '#FFFFFF',
          bgImage: '',
          defaultColor: '#7F8389',
          selectedColor: '#3385FF',
          contentType: 'iconText',
          blur: 0,
          fillBg: 0,
          opacity: 1,
          selectedBgColor: 'rgba(255,255,255,1)',
        },
        tabs: {
          list: [
            {
              is_default: 0,
              icon_checked: '',
              icon_normal: '',
              name: '',
              is_show: 0,
              jump_link: require('@/modules/app-design/assets/jsons/jump-link.json'),
            },
          ],
        },
      },
      currentIndex: 0,
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    tabList() {
      const list = this.$mergeModel(this.model, this.config).tabs.list
      return list.length
        ? list.filter((el) => el.is_show === 1)
        : this.bottomNavList.filter((el) => el.is_show === 1)
    },
    // currentIndex() {
    //   return this.value === -1 ? this.tabList.findIndex(t => t.is_default) : this.value;
    // }
  },
  watch: {
    tabList: {
      handler(val) {
        if (this._old) {
          if (
            JSON.stringify(val.map((v) => v.jump_link?.jump_config)) ===
            this._old
          ) {
          } else {
            const oldIndex = this.currentIndex
            this.currentIndex = -1
            setTimeout(() => {
              this.currentIndex = oldIndex
            }, 500)
          }
        }

        this._old = JSON.stringify(
          JSON.parse(JSON.stringify(val)).map((v) => v.jump_link?.jump_config)
        )
      },
      deep: true,
    },
  },
  created() {
    this.currentIndex =
      this.value === -1
        ? this.tabList.findIndex((t) => t.is_default)
        : this.value

    const val = this.tabList
    this._old = JSON.stringify(
      JSON.parse(JSON.stringify(val)).map((v) => v.jump_link?.jump_config)
    ) // 如果链接任意属性改变，就重新拉取装修数据
  },
  methods: {
    handleActive(i) {
      if (this.index === undefined) {
        this.currentIndex = i
        this.toDesign(this.tabList[i].jump_link?.jump_config)
        return // 表示是系统页的底部导航
      }
      if (i === this.currentIndex) {
        // 刷新
        this.currentIndex = -1
      }
      this.currentIndex = i
      // this.$emit('input', i)
      this.toggleJoinFloat(
        !!this.getParams(this.tabList[i].jump_link?.jump_config)?.id
      )
    },
    getParams(jump_config) {
      const { system_page, id, view, path } = jump_config

      if (system_page) return { system_page }
      if (id) return { id }
      if (view.system_page) return { system_page: view.system_page }
      if (view.id) return { id: view.id }
      return {}
    },
    toDesign(jump_config) {
      let that = this
      const { system_page, id, view, path } = jump_config
      if (system_page) {
        _toDesign({ system_page })
      } else if (id) {
        _toDesign({ id })
      } else if (view.system_page) {
        _toDesign({ system_page: view.system_page })
      } else if (view.id) {
        _toDesign({ id: view.id })
      } else if (path) {
        const isCustom =
          /^\/sub\/custom\/pages\/custom\?id=[\da-zA-Z]{8}$/gi.test(path)
        if (isCustom) {
          const idArr = path.match(/[\da-zA-Z]{8}/gi)
          if (idArr.length) {
            _toDesign({ id: idArr[0] })
          } else {
            this.$message.info('暂不支持快捷编辑')
          }
        } else {
          this.$message.info('暂不支持快捷编辑')
        }
      } else {
        this.$message.info('暂不支持快捷编辑')
      }
      function _toDesign(query) {
        // const {platform, id} = that.$route.params

        /* 进入页面装修后，重新请求缓存的分类数据 */
        // that.$store.commit('appDesign/setStateAttr', [{
        //   key: 'lastFetchTimestampRecord', val: {}
        // }])

        // let routerData = that.$router.resolve({
        //   name: "AppDesignPage",
        //   params: { platform: platform, id: id },
        //   query
        // });
        // window.open(routerData.href, "_blank"); // 新标签页打开

        // this.$router.replace() // 想要在当前打开

        that.switchPage({
          ...query,
          ...(query.system_page ? { id: 0 } : { system_page: '' }), // 适配注入的方法，两个都要传，系统页id为0，自定义页system_page为空字符串
        }) // 利用已封装好的切换（自定义页、系统页）页面方法
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.st-bottom-nav {
  width: 100%;

  .tip-area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fixed-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .template2 {
    height: 61px;
    bottom: 22px;
    display: flex;
    align-items: center;
    border-radius: 33px;
    overflow: hidden;
    padding: 0 20px;

    .template2-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      bottom: 0;
      left: 0;
      border-radius: 33px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .template2-mover {
      position: absolute;
      width: 49px;
      height: 49px;
      border-radius: 50%;
      background-color: white;
      z-index: 0;
    }

    .nav {
      padding: 0;
      justify-content: center;
      width: 49px;
      height: 49px;
      border-radius: 50%;
      z-index: 2;

      .template2-mover {
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
        background-color: white;
      }

      &.active {
        .template2-mover {
          opacity: 1;
        }
      }

      & + .nav {
        margin-left: 22rpx;
      }
    }

    .nav-logo {
      width: 18px !important;
      height: 18px !important;
      min-width: 18px !important;
      min-height: 18px !important;
    }
  }

  .bottom-nav {
    height: 50px;
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.fixed {
      /*position: fixed;*/
      /*bottom: 0;*/
      /*z-index: 9;*/
      /*border-top: 1px solid #f1f1f1;*/
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -3px;
        width: 100%;
        height: 0.5px;
        background-color: #f1f1f1;
      }
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include nowrap();

    .nav-logo {
      height: 25px;
      width: 25px;
      object-fit: contain;

      &.logo-only {
        width: 20px;
        height: 20px;
      }
    }

    .nav-text {
      min-height: 16px;
      font-size: 10px;
      line-height: 12px;
      padding-top: 4px;
      text-align: center;
      width: 100%;
      @include nowrap();

      &.text-only {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        padding-top: 0;
      }
    }

    .active {
      .big-nav-logo {
        position: absolute;
        z-index: 999;
        top: -23px;
        left: 50%;
        transform: translateX(-50%);
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        min-height: 48px !important;
      }
      .nav-text {
        position: absolute;
        bottom: 4.5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
