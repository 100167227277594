<template>
  <!--    backgroundColor: myList.length > 0 ? 'unset' : 'gray',-->
  <div
    class="st-banner"
    v-loading="loading"
    :style="[
      myRootStyle,
      {
        '--empty-icon-bg': defaultIconBgColor,
        backgroundColor: !myConfig.templateData.relative
          ? myConfig.normal.bgType === 'color'
            ? myConfig.normal.bgColor
            : ''
          : 'unset',
      },
    ]"
  >
    <!-- 模板1轮播样式 -->
    <div
      class="swiper-one"
      :class="[myConfig.templateData.styleType]"
      v-if="
        ['template1'].includes(myConfig.templateData.styleType) &&
        !speedChanging
      "
    >
      <!-- 不循环播放 -->
      <swiper ref="mySwiper" :options="optionsNoLoop" class="banner-swiper">
        <!-- 通过添加swiper-no-swiping类名实现禁止拖动 -->
        <swiper-slide
          class="swiper-no-swiping"
          v-for="(item, index) in myList"
          :key="item.id"
          :class="active === index ? 'banner active' : 'banner'"
        >
          <img
            v-if="!myConfig.templateData.relative"
            class="banner-image"
            :style="{
              boxShadow: myConfig.templateData.shadowVague
                ? `0 2px ${myConfig.templateData.shadowVague}px 0 rgba(122, 122, 122, 0.5)`
                : 'none',
              borderRadius: myConfig.templateData.borderRadius + 'px',
              objectFit: (
                item.banner_type === 3 ? item.video.thumbnail : item.image
              )
                ? 'cover'
                : 'none',
              backgroundColor: (
                item.banner_type === 3 ? item.video.thumbnail : item.image
              )
                ? ''
                : 'var(--empty-icon-bg)',
            }"
            :src="
              fixSpecialImageUrl(
                item.banner_type === 3 ? item.video.thumbnail : item.image,
                'large',
                index
              )
            "
            :data-index="index"
          />
          <div
            v-else
            class="banner-image"
            :style="{
              backgroundPosition: `center calc(-${20 + 44}px - 10px)`,
              backgroundImage: `url(${
                (item.banner_type === 3 ? item.video.thumbnail : item.image) ||
                'var(--empty-icon-bg)'
              })`,
              boxShadow: myConfig.templateData.shadowVague
                ? `0 2px ${myConfig.templateData.shadowVague}px 0 rgba(122, 122, 122, 0.5)`
                : 'none',
              borderRadius: myConfig.templateData.borderRadius + 'px',
            }"
            style="background-size: 375px"
          />
          <div
            class="banner-mask"
            :style="{
              borderRadius: `0 0 ${myConfig.templateData.borderRadius}px ${myConfig.templateData.borderRadius}px`,
            }"
            :data-index="index"
          >
            <p class="banner-name">{{ item.cover_title }}</p>
          </div>
        </swiper-slide>
        <!-- <div v-show="false" class="swiper-pagination"></div> -->
      </swiper>
    </div>
    <!-- 模板2轮播样式 -->
    <div
      class="swiper-template2"
      v-if="
        myConfig.templateData.styleType === 'template2' &&
        myList.length &&
        !speedChanging
      "
      :style="{
        padding:
          myConfig.templateData.playType === 'type2'
            ? `0 ${myConfig.templateData.rowPadding}px`
            : '',
      }"
    >
      <swiper class="swiper-two" ref="mySwiper" :options="options">
        <swiper-slide
          class="swiper-item"
          v-for="(item, index) in myList"
          :key="item.id"
          v-if="myList.length"
          :style="{
            zIndex: active === index ? 1 : 0,
          }"
        >
          <div
            class="item-box"
            :style="{
              padding:
                myConfig.templateData.playType === 'type1'
                  ? `0 ${myConfig.templateData.rowPadding}px`
                  : '',
            }"
            style="height: 100%"
          >
            <div
              class="item-content"
              :style="{
                boxShadow: myConfig.templateData.shadowVague
                  ? `0 2px ${myConfig.templateData.shadowVague}px 0 rgba(122, 122, 122, 0.5)`
                  : 'none',
                borderRadius: myConfig.templateData.borderRadius + 'px',
              }"
            >
              <el-image
                :src="
                  fixSpecialImageUrl(
                    item.banner_type === 3 ? item.video.thumbnail : item.image,
                    'large',
                    index
                  )
                "
                class="advert-img"
                :fit="
                  (item.banner_type === 3 ? item.video.thumbnail : item.image)
                    ? 'cover'
                    : 'none'
                "
              >
              </el-image>
              <div class="title-cover" v-if="item.cover_title">
                {{ item.cover_title }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div
        class="dots-area"
        v-if="myList.length !== 1"
        :style="{
          right:
            myConfig.templateData.indicatorType === 'template1'
              ? myConfig.templateData.rowPadding + 'px'
              : 0,
        }"
      >
        <div
          :class="['swiper-dots', myConfig.templateData.indicatorType]"
          :style="{
            right: 12 + 'px',
          }"
        >
          <div
            :class="['item-dot', i === active + 1 ? 'active' : '']"
            v-for="i in myList.length ? myList.length : 4"
            :key="i"
          ></div>
        </div>
      </div>
    </div>
    <!-- 模板3轮播样式 -->
    <div
      style="overflow: hidden"
      class="swiper-one"
      :class="[myConfig.templateData.styleType]"
      v-if="
        ['template3'].includes(myConfig.templateData.styleType) &&
        !speedChanging
      "
    >
      <!-- 不循环播放 -->
      <swiper
        ref="mySwiper"
        :options="optionsNoLoop"
        class="banner-swiper"
        style="height: 232px; overflow: visible"
      >
        <!-- 通过添加swiper-no-swiping类名实现禁止拖动 -->
        <swiper-slide
          class="swiper-no-swiping"
          v-for="(item, index) in myList"
          :key="item.id"
          :class="active === index ? 'banner active' : 'banner'"
          style="width: 100%"
        >
          <img
            v-if="!myConfig.templateData.relative"
            class="banner-image"
            :style="{
              boxShadow: myConfig.templateData.shadowVague
                ? `0 2px ${myConfig.templateData.shadowVague}px 0 rgba(122, 122, 122, 0.5)`
                : 'none',
              borderRadius: myConfig.templateData.borderRadius + 'px',
              objectFit: (
                item.banner_type === 3 ? item.video.thumbnail : item.image
              )
                ? 'cover'
                : 'none',
              backgroundColor: (
                item.banner_type === 3 ? item.video.thumbnail : item.image
              )
                ? ''
                : 'var(--empty-icon-bg)',
            }"
            :src="
              fixSpecialImageUrl(
                item.banner_type === 3 ? item.video.thumbnail : item.image,
                'large',
                index
              )
            "
            :data-index="index"
          />
          <div
            v-else
            class="banner-image"
            :style="{
              backgroundPosition: `center calc(-${20 + 44}px - 10px)`,
              backgroundImage: `url(${
                (item.banner_type === 3 ? item.video.thumbnail : item.image) ||
                'var(--empty-icon-bg)'
              })`,
              boxShadow: myConfig.templateData.shadowVague
                ? `0 2px ${myConfig.templateData.shadowVague}px 0 rgba(122, 122, 122, 0.5)`
                : 'none',
              borderRadius: myConfig.templateData.borderRadius + 'px',
            }"
            style="background-size: 375px"
          />
        </swiper-slide>
        <!-- <div v-show="false" class="swiper-pagination"></div> -->
      </swiper>
      <div
        :style="{
          borderRadius: `0 0 ${myConfig.templateData.borderRadius}px ${myConfig.templateData.borderRadius}px`,
        }"
      >
        <p class="active-name" v-if="myList[active]">
          {{ myList[active].cover_title }}
        </p>
      </div>
      <div class="active-box">
        <img src="../../assets/images/st-banner/line.png" alt="" />
        {{ active >= 9 ? '' : '0' }}{{ active + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { advertList } from '../../api/page'
import componentMixin from '../../mixins/component-mixin'

export default {
  name: 'st-banner',
  mixins: [componentMixin],
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    pagePadding: {
      type: Number,
      default: 16,
    },
  },
  data() {
    let that = this
    return {
      pageset: this.$route.query.pageset,
      loading: false,
      speedChanging: false,
      defaultList: require('../../assets/jsons/default-list.json').bannerList,
      myList: [],
      active: 0,
      options: {
        loop: true,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        speed: 500,
        slidesPerView: 'auto',
        centeredSlides: true,
        // 修改swiper自己或子元素时，自动初始化swiper
        // observer: true,
        // 修改swiper的父元素时，自动初始化swiper
        // observeParents: true,
        // 默认为true，Swiper会强制加载所有图片后才初始化。
        // preloadImages: false,
        on: {
          slideChange: function () {
            that.active = this.realIndex
          },
          // click: function (e) {
          //   console.log('click', e.target.dataset.index)
          // }
        },
      },
      optionsNoLoop: {
        loop: false,
        autoplay: false,
        speed: 500,
        slidesPerView: 'auto',
        centeredSlides: true,
        // 修改swiper自己或子元素时，自动初始化swiper
        // observer: true,
        // 修改swiper的父元素时，自动初始化swiper
        // observeParents: true,
        // 默认为true，Swiper会强制加载所有图片后才初始化。
        // preloadImages: false,
        on: {
          slideChange: function () {
            that.active = this.realIndex
          },
        },
      },
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          borderRadius: 0,
        },
        templateData: {
          styleType: 'template1',
          shadowVague: 0,
          borderRadius: 0,
          rowPadding: 0,
          speed: 1,
          playType: 'type1',
          indicatorType: 'template1',
          relative: false,
        },
        dataConfig: {
          dataType: 'filter',
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
          dataAll: 1,
          dataFilter: [''],
          type: [0],
        },
        loadingConfig: {
          limit: 10,
        },
      },
      timer: null,
    }
  },
  watch: {
    'myConfig.templateData.rowPadding'(val) {
      this.changeSpeed()
    },
    'myConfig.templateData.speed'(val) {
      this.changeSpeed()
    },
    'myConfig.templateData.styleType'(val) {
      this.changeSpeed()
    },
    'myConfig.loadingConfig.limit'(val) {
      this.getAdvertList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getAdvertList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getAdvertList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val) {
      this.getAdvertList(false)
    },
    'myConfig.dataConfig.dataFilter'(val) {
      this.getAdvertList(false)
    },
    pagePadding(val) {
      this.changeSpeed()
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper
    },
  },
  created() {
    this.getAdvertList()
    this.changeSpeed()
    // this.options.loop = this.list.length > 2;
  },
  methods: {
    //获取广告列表数据
    getAdvertList(notCancel) {
      this.loading = true
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        limit: this.myConfig.loadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        ids,
        category_all:
          this.myConfig.dataConfig.dataType === 'select' ||
          this.myConfig.dataConfig.dataAll ||
          this.myConfig.dataConfig.dataFilter.length == 0
            ? 1
            : 0,
        category_ids: this.myConfig.dataConfig.dataAll
          ? []
          : this.myConfig.dataConfig.dataFilter,
        banner_type_all: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        banner_types:
          this.myConfig.dataConfig.dataType === 'select'
            ? [0, 2, 3]
            : this.myConfig.dataConfig.type,
      }
      advertList(requestData, notCancel)
        .then((res) => {
          const { data } = res
          this.myList = data.length ? data : this.defaultList
          this.loading = false
          this.changeSpeed()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 修改轮播速度
    changeSpeed() {
      /**
       * 轮播图自动播放会导致截图生成位置错乱，
       * 所以，当编辑备份时，禁止自动播放
       */
      if (this.pageset) return
      this.loading = true
      this.speedChanging = true
      this.active = 0
      this.options.autoplay.delay = this.myConfig.templateData.speed * 1000
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (this.swiper?.swiper) {
          this.swiper.swiper.update()
        }
        this.loading = false
        this.speedChanging = false
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
.st-banner {
  background-size: cover;
  background-position: center;
  min-height: 140px;
  .banner-swiper {
    display: flex;
    height: 154px;
    width: 100%;
    overflow: hidden;
    justify-content: center;
  }

  .banner {
    flex-shrink: 0;
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.active {
      .banner-image {
        transition: all 0.3s;
        width: 100%;
        height: 134px;
        &.default {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #dcdfe6;
          .default-icon {
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }
      }

      .banner-mask {
        padding: 11px 17px;
        left: 0;
        height: 64px;
        width: 100%;
        bottom: 10px;
      }
    }

    .banner-image {
      max-height: 100%;
      max-width: 100%;
      width: calc(100% - 20px);
      height: 126px;
      &.default {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #dcdfe6;
        .default-icon {
          width: 28px;
          height: 28px;
          z-index: 2;
        }
      }
    }

    .banner-name {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .banner-mask {
      box-sizing: border-box;
      padding: 11px 17px;
      position: absolute;
      left: 12px;
      height: 60px;
      width: 311px;
      bottom: 14px;
      z-index: 3;
    }
  }
}

.swiper-two {
  .swiper-item {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 156px;
    // overflow: hidden;
    .item-content {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
  .advert-img {
    width: 100%;
    height: 100%;
    &.default {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #dcdfe6;
      .default-icon {
        width: 28px;
        height: 28px;
        z-index: 2;
      }
    }

    ::v-deep.el-image__inner {
      background-color: var(--empty-icon-bg);
    }
  }
  .title-cover {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 84px;
    padding: 20px 17px 10px;
    bottom: 0;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
  }
}

.swiper-pagination {
  bottom: 10px;
  width: 100%;
  display: none;

  // &.template1 {
  //   ::v-deep.swiper-pagination-bullet {
  //     margin-left: 4px;
  //     height: 4px;
  //     width: 4px;
  //     border-radius: 4px;
  //     transition: all 0.5s ease;
  //     background-color: #fff;
  //     opacity: 0.3;

  //     &.swiper-pagination-bullet-active {
  //       height: 4px;
  //       width: 12px;
  //       opacity: 1;
  //     }
  //   }
  // }
  // &.template2 {
  //   ::v-deep.swiper-pagination-bullet {
  //     margin-left: 4px;
  //     height: 4px;
  //     width: 4px;
  //     border-radius: 4px;
  //     transition: all 0.5s ease;
  //     background-color: #3a7aff;
  //     opacity: 0.3;

  //     &.swiper-pagination-bullet-active {
  //       height: 4px;
  //       width: 12px;
  //       opacity: 1;
  //     }
  //   }
  // }
  // &.template3 {
  //   ::v-deep.swiper-pagination-bullet {
  //     margin-left: 4px;
  //     height: 4px;
  //     width: 8px;
  //     border-radius: 4px;
  //     transition: all 0.5s ease;
  //     background-color: #3a7aff;
  //     opacity: 0.3;

  //     &.swiper-pagination-bullet-active {
  //       height: 4px;
  //       width: 12px;
  //       opacity: 1;
  //     }
  //   }
  // }
  // &.template4 {
  //   &::v-deep:first-child {
  //     border-radius: 4px 0 0 4px !important;
  //   }
  //   &::v-deep:last-child {
  //     border-radius: 0 4px 4px 0 !important;
  //   }
  //   ::v-deep.swiper-pagination-bullet {
  //     height: 4px;
  //     width: 12px;
  //     border-radius: 0px;
  //     transition: all 0.5s ease;
  //     background-color: #3a7aff;
  //     opacity: 0.3;

  //     &.swiper-pagination-bullet-active {
  //       height: 4px;
  //       width: 12px;
  //       opacity: 1;
  //       border-radius: 4px !important;
  //     }
  //   }
  // }
}

.swiper-template2 {
  width: 100%;
  height: auto;

  .dots-area {
    position: absolute;
    bottom: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
  }

  .swiper-dots {
    display: flex;

    &.template1 {
      position: absolute;

      .item-dot {
        height: 4px;
        width: 8px;
        border-radius: 4px;
        transition: all 0.5s ease;
        background-color: var(--main-color);
        opacity: 0.3;

        &.active {
          height: 4px;
          width: 12px;
          opacity: 1;
        }
      }
    }

    &.template2 {
      .item-dot {
        height: 4px;
        width: 4px;
        border-radius: 4px;
        transition: all 0.5s ease;
        background-color: var(--main-color);
        opacity: 0.3;

        &.active {
          height: 4px;
          width: 12px;
          opacity: 1;
        }
      }
    }

    &.template3 {
      .item-dot {
        height: 4px;
        width: 8px;
        border-radius: 4px;
        transition: all 0.5s ease;
        background-color: var(--main-color);
        opacity: 0.3;

        &.active {
          height: 4px;
          width: 12px;
          opacity: 1;
        }
      }
    }
    &.template4 {
      .item-dot + .item-dot {
        margin-left: 0px;
      }
      .item-dot {
        height: 4px;
        width: 12px;
        border-radius: 0px;
        transition: all 0.5s ease;
        background-color: var(--main-color);
        opacity: 0.3;

        &.active {
          height: 4px;
          width: 12px;
          opacity: 1;
          border-radius: 4px !important;
        }
      }

      :first-child {
        border-radius: 4px 0 0 4px !important;
      }
      :last-child {
        border-radius: 0 4px 4px 0 !important;
      }
    }
    &.template5 {
      .item-dot {
        width: 8px;
        height: 4px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        &.active {
          width: 12px;
          height: 4px;
          background: #ffffff;
          border-radius: 4px;
        }
      }
    }
    .item-dot + .item-dot {
      margin-left: 4px;
    }
  }
}

.swiper-one.template3 {
  .swiper-no-swiping {
    width: 100%;
    .banner-image {
      /*box-shadow: none !important;*/
      height: 332px;
    }
  }

  .active-name {
    color: #1a1a1a;
    line-height: 27px;
    min-height: 27px;
    font-weight: 500;
    margin-top: 8px;
    padding: 0px 12px;
    font-size: 18px;
  }

  .active-box {
    display: flex;
    color: rgba(179, 179, 179, 1);
    font-size: 14px;
    height: 33px;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    img {
      width: 70px;
    }
  }
}
</style>
