<!--
 * @Author: dongjia
 * @Date: 2021-09-01 14:46:25
 * @LastEditTime: 2021-10-12 18:30:26
 * @LastEditors: aleaner
 * @Description: 通讯录内容组件
 * @FilePath: \app-design\components\Preview\StMailContent.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="mail-content">
    <div class="mail-tabs" v-if="realTabArr.length">
      <div
        :class="['tab-item', active === item.value ? 'active' : '']"
        v-for="(item, index) in realTabArr"
        :key="index"
        @click="active = item.value"
      >
        <p class="item-name">{{ item.label }}</p>
        <div class="item-active"></div>
      </div>
      <div :class="['tab-item', 'filter-box']">
        <div class="item-name">
          <img src="../../assets/images/st-mail-list/filter.png" alt="" />
          筛选
        </div>
        <div class="item-active"></div>
      </div>
    </div>
    <!-- tab 栏目一个都没有的时候 -->
    <div
      class="filter-bar"
      v-else
    >
      <div class="filter-box">
        <img
          class="image filter-icon"
          src="../../assets/images/st-mail-list/filter.png"
        />
        <p> 筛选</p>
      </div>
    </div>
    <div
      class="mail-filter"
      :style="{
        marginTop: realTabArr.length ? '18px' : '0px',
      }"
      v-if="
        (active === 'allConfig' && myConfig[active].showFans) ||
        (active !== 'allConfig' && myConfig[active].showFilter)
      "
    >
      <!-- <div v-if="active === 'allConfig' && myConfig[active].showFans" class="filter-item">
        我的关注 20
      </div> -->
      <div
        v-if="active !== 'allConfig'"
        class="filter-item"
        :class="childrenTabActive === 0 ? 'active' : ''"
      >
        全部
      </div>
      <div
        v-if="active !== 'allConfig'"
        class="filter-item"
        :class="childrenTabActive === item ? 'active' : ''"
        v-for="(item, index) in myConfig[active].filterData"
        :key="index"
      >
        {{ filterArr[item - 1] }}
      </div>
    </div>
    <!-- <div class="expand-btn" v-if="active === 'allConfig' && myConfig[active].showExpand" :style="{
      marginTop: active === 'allConfig' && myConfig[active].showFans?'':'12px'
      }">展开<img class="expand-icon" src="../../assets/images/st-mail-list/expand-icon.png" /></div> -->
    <div
      class="member-num-top"
      v-if="
        memberNumTop &&
        myConfig.allConfig.showMemberNum &&
        active === 'allConfig'
      "
      :style="{
        marginTop:
          myConfig[active].showExpand ||
          !(
            (active === 'allConfig' && myConfig[active].showFans) ||
            (active !== 'allConfig' && myConfig[active].showFilter)
          )
            ? '12px'
            : '',
      }"
    >
      共
      <span style="color: #3a3a3a; font-size: 14px">{{ memberNum }}</span>
      名会员
    </div>
    <div class="member-content" v-if="memberList.length">
      <div
        class="member-list"
        v-if="active === 'allConfig'"
        :style="{
          width: active === 'allConfig' ? '320px' : '341px',
        }"
      >
        <div class="list-item" v-for="(item, index) in memberList" :key="index">
          <div class="item-initial">{{ item.Initials }}</div>
          <div class="item-list">
            <div class="member-item" v-for="(member, i) in item.list" :key="i">
              <div class="member-left">
                <el-image
                  class="member-avatar"
                  :src="member.avatar || defaultAvatar"
                  fit="cover"
                ></el-image>
                <div class="member-msg">
                  <div class="msg-top">
                    {{ member.nickname }}
                    <img
                      class="member-sign"
                      v-if="
                        myConfig.allConfig.showMemberSign &&
                        memberForm.unit_user_enable &&
                        memberForm.personal_user_enable
                      "
                      :src="member.sign === 1 ? yellowIcon : blueIcon"
                    />
                    <!--                    <span v-if="myConfig.allConfig.showMemberSign && memberForm.unit_user_enable && memberForm.personal_user_enable"><img :src="member.sign === 1 ? yellowIcon : blueIcon" />{{member.sign === 1 ? '单位' : '会员'}}</span>-->
                  </div>
                  <div class="msg-bottom">{{ member.title_name }}</div>
                </div>
              </div>
              <div class="member-right" v-if="active !== 'allConfig'">
                <div
                  class="member-info"
                  v-if="
                    active === 'nearbyConfig' &&
                    myConfig.nearbyConfig.showDistance
                  "
                >
                  {{ member.distance }}
                </div>
                <div
                  class="member-info"
                  v-if="
                    active === 'nearbyConfig' && myConfig.nearbyConfig.showArea
                  "
                >
                  {{ member.area }}
                </div>
                <div
                  class="member-info"
                  v-if="
                    active === 'sameCityConfig' &&
                    myConfig.sameCityConfig.showCity
                  "
                >
                  {{ member.city }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="item-list"
        v-if="active !== 'allConfig'"
        :style="{
          width: active === 'allConfig' ? '320px' : '341px',
        }"
      >
        <div
          class="member-item"
          v-for="(member, i) in memberList[0].list"
          :key="i"
        >
          <div class="member-left">
            <el-image
              class="member-avatar"
              :src="member.avatar || defaultAvatar"
              fit="cover"
            ></el-image>
            <div class="member-msg">
              <div class="msg-top">
                {{ member.nickname }}
                <img
                  class="member-sign"
                  v-if="
                    myConfig[active].showMemberSign &&
                    memberForm.unit_user_enable &&
                    memberForm.personal_user_enable
                  "
                  :src="member.sign === 1 ? yellowIcon : blueIcon"
                />
              </div>
              <div class="msg-bottom">{{ member.title_name }}</div>
            </div>
          </div>
          <div class="member-right" v-if="active !== 'allConfig'">
            <div
              class="member-info"
              v-if="
                active === 'nearbyConfig' && myConfig.nearbyConfig.showDistance
              "
            >
              {{ member.distance }}
            </div>
            <div
              class="member-info"
              v-if="active === 'nearbyConfig' && myConfig.nearbyConfig.showArea"
            >
              {{ member.area }}
            </div>
            <div
              class="member-info"
              v-if="
                active === 'sameCityConfig' && myConfig.sameCityConfig.showCity
              "
            >
              {{ member.city }}
            </div>
          </div>
        </div>
      </div>
      <div class="member-Initials" v-if="active === 'allConfig'">
        <div class="Initial-item" v-for="i in 26">
          {{ String.fromCharCode(64 + i) }}
        </div>
        <div class="Initial-item">#</div>
      </div>
    </div>
    <div
      class="member-num-bottom"
      v-if="
        !memberNumTop &&
        myConfig.allConfig.showMemberNum &&
        active === 'allConfig'
      "
    >
      共
      <span style="color: #3a3a3a; font-size: 14px">{{ memberNum }}</span>
      名会员
    </div>
  </div>
</template>

<script>
import { getRoleSetting } from "@/modules/organization-admin/api/other-config";

export default {
  name: "StMailContent",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      memberList: require("../../assets/jsons/default-list.json").member_list,
      model: {
        allConfig: {
          showFans: 1,
          showExpand: 1,
          showMemberNum: 1,
          numPosition: "top",
          showMemberSign: 1,
        },
        nearbyConfig: {
          isShow: 1,
          showFilter: 1,
          filterData: [1, 2],
          showDistance: 1,
          showArea: 1,
          showMemberSign: 1,
        },
        sameCityConfig: {
          isShow: 1,
          showFilter: 1,
          filterData: [1, 2],
          showCity: 1,
          showMemberSign: 1,
        },
      },
      tabArr: [
        { value: "allConfig", label: "全部" },
        { value: "nearbyConfig", label: "附近" },
        { value: "sameCityConfig", label: "同城" },
      ],
      filterArr: ["只看男生", "只看女生"],
      active: "allConfig",
      childrenTabActive: 0,
      defaultAvatar: require("../../assets/images/st-spirit-list/circle-avatar1.png"),
      yellowIcon: require("../../assets/images/st-mail-list/unit-sign.png"),
      blueIcon: require("../../assets/images/st-mail-list/user-sign.png"),
      memberForm: {
        personal_user_enable: 1,
        unit_user_enable: 1,
      },
    };
  },
  watch: {
    "myConfig.allConfig": {
      deep: true,
      handler(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.active = "allConfig";
        }
      },
    },
    "myConfig.nearbyConfig": {
      deep: true,
      handler(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.active = val.isShow === 1 ? "nearbyConfig" : "allConfig";
        }
      },
    },
    "myConfig.sameCityConfig": {
      deep: true,
      handler(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.active = val.isShow === 1 ? "sameCityConfig" : "allConfig";
        }
      },
    },
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    memberNumTop() {
      return (
        this.memberList.length && this.myConfig.allConfig.numPosition === "top"
      );
    },
    memberNum() {
      let num = 0;
      this.memberList.forEach((el) => {
        num += el.list.length;
      });
      return num;
    },
    realTabArr() {
      let newTabArr = [];
      this.tabArr.forEach((el) => {
        if (
          this.myConfig[el.value].isShow === undefined ||
          this.myConfig[el.value].isShow === 1
        ) {
          newTabArr.push(el);
        }
      });
      newTabArr = newTabArr.length === 1 ? [] : newTabArr;
      return newTabArr;
    },
  },
  created() {
    getRoleSetting()
      .then((res) => {
        this.memberForm = res.data;
      })
      .catch(() => {});
  },
};
</script>

<style lang="scss" scoped>
.mail-content {
  min-height: 100px;
  padding: 16px 13px 16px 17px;
  .mail-tabs {
    display: flex;
    align-items: center;
    .tab-item + .tab-item {
      margin-left: 16px;
    }
    .tab-item {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-name {
        font-size: 14px;
        color: #666666;
        line-height: 16px;
      }

      .item-active {
        margin-top: 6px;
        width: 16px;
        height: 4px;
        border-radius: 2px;
      }

      &.active {
        .item-name {
          font-size: 16px;
          font-weight: bold;
          color: #333c50;
          line-height: 16px;
        }
        .item-active {
          background: linear-gradient(
            90deg,
            var(--help-color) 0%,
            var(--main-color) 100%
          );
        }
      }
    }
    .filter-box {
      position: absolute;
      right: 0;
      .item-name {
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
      }
    }
  }
  .filter-bar {
  background-color: #fff;
  .filter-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    p {
      font-size: 14px;
      color: #808080;
    }
  }
}
  .mail-filter {
    display: flex;
    flex-wrap: wrap;

    .filter-item {
      margin-right: 10px;
      margin-bottom: 12px;
      padding: 5px 14px;
      border-radius: 15px;
      border: 1px solid #e0e0e0;
      font-size: 14px;
      color: #333c50;
      line-height: 18px;

      &.active {
        color: #ffffff;
        background-color: var(--main-color);
        border: none;
      }
    }
  }
  .expand-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 5px 16px;
    font-size: 14px;
    color: #4267b9;
    line-height: 18px;
    border-radius: 15px;
    border: 1px solid #e0e0e0;
    .expand-icon {
      width: 10px;
      height: 6px;
      margin-left: 6px;
    }
  }
  .member-num-top {
    padding-left: 8px;
    font-size: 12px;
    color: #797979;
    line-height: 17px;
  }
  .member-num-bottom {
    margin-top: 19px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #797979;
    line-height: 17px;
  }
  .member-content {
    display: flex;
    .member-list {
      display: flex;
      flex-direction: column;

      .list-item {
        margin-top: 8px;
        .item-initial {
          flex-shrink: 0;
          font-size: 12px;
          color: #797979;
          line-height: 17px;
          padding-left: 8px;
        }
      }
    }
    .member-Initials {
      margin-top: 46px;
      margin-left: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .Initial-item {
        font-size: 10px;
        color: #797979;
        line-height: 14px;
      }
    }
  }
}

.item-list {
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .member-item + .member-item {
    margin-top: 8px;
  }
  .member-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #f2f2f2;
    border-radius: 16px;
    padding: 16px;
    .member-left {
      display: flex;
      align-items: center;
      .member-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      .member-msg {
        margin-left: 12px;
        .msg-top {
          font-size: 16px;
          color: #000000;
          line-height: 16px;
        }
        .msg-bottom {
          margin-top: 8px;
          font-size: 12px;
          color: #969799;
          line-height: 12px;
        }
      }

      .member-sign {
        border-radius: 8px;
        height: 16px;
        vertical-align: bottom;
      }
    }
    .member-right {
      .member-info {
        text-align: right;
        font-size: 12px;
        color: #b8b8b8;
        line-height: 20px;
      }
    }
  }
}
</style>
