// import store from "@/base/store";

export default {
  getRules(getVm, value, platform) {
    return {
      normal: {
        title: '常规设置',
        rules: [
          {
            "type": "StSpecialInput",
            "field": "titleConfig",
            "title": "页面标题",
            "display": true,
            update: (val, rule, fApi) => {
              const arr = [1, 2, 3]
              arr.forEach((el) => {
                fApi.rule[el].display = val.isShow === 1
              })
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "fontWeight",
            "native": true,
            "display": true,
            "props": {
              "title": "文本粗细",
              "options": [
                {
                  "label": "常规体",
                  "value": "normal"
                },
                {
                  "label": "加粗体",
                  "value": "bold"
                }
              ]
            }
          },
          {
            "type": "StNumberSlider",
            "field": "fontSize",
            "native": true,
            "display": true,
            "props": {
              "label": "文本字号",
              "min": 12,
              "max": 40
            }
          },
          {
            "type": "StColorPicker",
            "field": "fontColor",
            "title": "文本颜色",
            "display": true,
            "props": {
              "defaultColor": "#000000"
            }
          },
          {
            "type": "StNumberSlider",
            "field": "rowPadding",
            "native": true,
            "display": false,
            "props": {
              "label": "两侧边距",
              "min": 0,
              "max": 60
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "statusBarColor",
            "native": true,
            display: platform !== 'h5',
            "props": {
              "title": "状态栏颜色",
              "options": [
                {
                  "label": "黑色",
                  "value": "black"
                },
                {
                  "label": "白色",
                  "value": "white"
                }
              ]
            },
            update: v => {
              const fApi = value()[0]
              const t = fApi.rule[0]
              console.log(t.field, v)
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "upStatusBarColor",
            "native": true,
            display: platform !== 'h5',
            "props": {
              "title": "状态栏颜色(上滑后)",
              "options": [
                {
                  "label": "黑色",
                  "value": "black"
                },
                {
                  "label": "白色",
                  "value": "white"
                }
              ]
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "bgType",
            "native": true,
            "props": {
              "title": "背景设置",
              "options": [
                {
                  "label": "颜色",
                  "value": "color"
                },
                {
                  "label": "图片",
                  "value": "image"
                }
              ]
            }
          },
          {
            "type": "StColorPicker",
            "field": "bgColor",
            "title": "背景颜色",
            "display": true,
            "props": {
              "showAlpha": true,
              defaultColor: "#f8f8f8"
            },
            "link": ["bgType"],
            "update": (val, rule, fApi) => {
              rule.display = fApi.form.bgType === "color"
            }
          },
          {
            "type": "SingleMediaWall",
            "field": "bgImage",
            "title": "背景图片",
            "display": true,
            "props": {
              "width": 60,
              "height": 60,
              "addText": ""
            },
            "style": {
              "float": "right"
            },
            "link": ["bgType"],
            "update": (val, rule, fApi) => {
              rule.display = fApi.form.bgType === "image"
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "showHeaderJump",
            "native": true,
            "display": false,
            "props": {
              "title": "头部跳转",
              "options": [
                {
                  "label": "显示",
                  "value": 1
                },
                {
                  "label": "隐藏",
                  "value": 0
                }
              ]
            }
          },
        ]
      },
      "header": {
        isShow: 1,
        "title": "顶部样式设置",
        "rules": [
          {
            "type": "StRadioButtonGroup",
            "field": "template",
            "native": true,
            display: false,
            "props": {
              "title": "选择模板",
              "options": [
                {
                  "label": "方形",
                  "value": "square"
                },
                {
                  "label": "圆形",
                  "value": "circle"
                },
                {
                  "label": "自定义",
                  "value": "custom"
                }
              ]
            },
            "update": (val, rule, fApi) => {
              fApi.form.height =
                fApi.form.template === "circle"
                  ? 180
                  : fApi.form.template === "square"
                    ? 0
                    : fApi.form.height
              // fApi.form.radian = fApi.form.template === 'circle' ? 70 : fApi.form.template === 'square' ? 0 : fApi.form.radian
              fApi.form.radian = fApi.form.template === "circle" ? 70 : 0
            }
          },
          {
            "type": "StNumberSlider",
            "field": "height",
            "native": true,
            "display": false,
            "props": {
              "label": "高度",
              "min": 0,
              "max": 1000
            },
            "link": ["template"],
            "update": (val, rule, fApi) => {
              // rule.display = fApi.form.template === "custom" ? true : false
            }
          },
          {
            "type": "StNumberSlider",
            "field": "radian",
            "native": true,
            "display": false,
            "props": {
              "label": "弧度",
              "min": 0,
              "max": 100
            },
            "link": ["template"],
            "update": (val, rule, fApi) => {
              // rule.display = fApi.form.template === "custom" ? true : false
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "bgType",
            "native": true,
            "props": {
              "title": "背景设置",
              "options": [
                {
                  "label": "跟随主题色",
                  "value": "theme"
                },
                {
                  "label": "自定义颜色",
                  "value": "color"
                },
                // {
                //   "label": "图片",
                //   "value": "image"
                // }
              ]
            }
          },
          // {
          //   "type": "StRadioButtonGroup",
          //   "field": "bgType",
          //   "native": true,
          //   "props": {
          //     "title": "背景设置",
          //     "options": [
          //       {
          //         "label": "颜色",
          //         "value": "color"
          //       },
          //       {
          //         "label": "图片",
          //         "value": "image"
          //       }
          //     ]
          //   }
          // },
          {
            "type": "StColorPicker",
            "field": "bgColor",
            "title": "背景颜色",
            "display": true,
            "props": {
              "showAlpha": true,
              defaultColor: "rgba(255,255,255,0)"
            },
            "link": ["bgType"],
            "update": (val, rule, fApi) => {
              rule.display = fApi.form.bgType === "color" ? true : false
            }
          },
          {
            "type": "SingleMediaWall",
            "field": "bgImage",
            "title": "背景图片",
            "display": false,
            "props": {
              "width": 60,
              "height": 60,
              "showInline": true,
              "addText": ""
            },
            "style": {
              "float": "right"
            },
            "link": ["bgType"],
            "update": (val, rule, fApi) => {
              // rule.display = fApi.form.bgType === "image" ? true : false
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "bgPosition",
            "native": true,
            "display": false,
            "props": {
              "title": "交互方式",
              "options": [
                {
                  "label": "无",
                  "value": "normal"
                },
                {
                  "label": "页面滑动时固定",
                  "value": "fixed"
                }
              ]
            },
            "link": ["bgType"],
            "update": (val, rule, fApi) => {
              rule.display = fApi.form.bgType === "image"
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "upBgType",
            "native": true,
            "display": false,
            "props": {
              "title": "上滑背景设置",
              "options": [
                {
                  "label": "颜色",
                  "value": "color"
                },
                {
                  "label": "图片",
                  "value": "image"
                }
              ]
            }
          },
          {
            "type": "StColorPicker",
            "field": "upBgColor",
            "title": "上滑背景颜色",
            "display": false && platform !== 'h5',
            "props": {
              "showAlpha": true
            },
            "link": ["upBgType"],
            "update": (val, rule, fApi) => {
              // rule.display = fApi.form.upBgType === "color" && platform !== 'h5'
            }
          },
          {
            "type": "SingleMediaWall",
            "field": "upBgImage",
            "title": "上滑背景图片",
            "display": false,
            "props": {
              "width": 60,
              "height": 60,
              "showInline": true,
              "addText": ""
            },
            "style": {
              "float": "right"
            },
            "link": ["upBgType"],
            "update": (val, rule, fApi) => {
              rule.display = fApi.form.upBgType === "image"
            }
          }
        ]
      },
      "shareConfig": {
        "title": "分享设置",
        "rules": [
          {
            "type": "ElInput",
            "field": "shareTitle",
            "title": "分享标题",
            "props": {
              "placeholder": "请输入分享标题"
            }
          },
          {
            "type": "SingleMediaWall",
            "field": "image",
            "title": "分享图",
            "props": {
              "width": 60,
              "height": 60,
              "showInline": true,
              "addText": ""
            },
            "children": [
              {
                "type": "div",
                "slot": "info",
                "class": "st-inline-img-info",
                "children": ["建议图片长宽5:4，如不设置，则自动截取页面首屏"]
              }
            ]
          }
        ]
      }
    }
  }
}
