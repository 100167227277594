var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-nav",style:({
    marginTop: _vm.myConfig.normal.marginTop + 'px !important',
    marginLeft: ((_vm.myConfig.normal.rowMargin) + "px"),
    marginRight: ((_vm.myConfig.normal.rowMargin) + "px"),
    backgroundColor: _vm.myConfig.normal.bgColor,
    borderRadius: ((_vm.myConfig.normal.topRadius) + "px " + (_vm.myConfig.normal.topRadius) + "px 0 0"),
  })},[_c('div',{staticClass:"nav-list",style:({
      paddingTop: _vm.myConfig.normal.navPaddingTop + 'px',
      marginLeft: ((_vm.myConfig.normal.navRowMargin) + "px"),
      marginRight: ((_vm.myConfig.normal.navRowMargin) + "px"),
    })},_vm._l((_vm.myConfig.tabs.list.length
        ? _vm.tabList
        : _vm.defaultList),function(item,index){return _c('div',{key:index,staticClass:"nav-item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.currentIndex = index}}},[(item.leftIcon)?_c('el-image',{staticClass:"nav-image",attrs:{"src":item.leftIcon,"fit":"cover"}}):_vm._e(),_c('div',{class:['nav-name', index === _vm.currentIndex ? 'active' : ''],style:({
          color:
            index === _vm.currentIndex
              ? _vm.myConfig.normal.selectedColor
              : _vm.myConfig.normal.defaultColor,
        })},[_vm._v(" "+_vm._s(item.name ? item.name : ("导航" + (_vm.ch_sort[index])))+" "),(index === _vm.currentIndex)?_c('div',{staticClass:"nav-active"}):_vm._e()]),(item.rightIcon)?_c('el-image',{staticClass:"nav-image",attrs:{"src":item.rightIcon,"fit":"cover"}}):_vm._e()],1)}),0),(!_vm.tabList.length)?_c('div',{staticClass:"tip-area",style:({
    backgroundColor: _vm.myConfig.normal.contentBgColor,
  })},[_vm._m(0)]):_vm._e(),_vm._l((_vm.tabList),function(item,index){return [(item.jump_link)?[(item.jump_link.jump_config)?[_c('page-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === index),expression:"currentIndex === index"}],key:item.name,attrs:{"id":item.jump_link.jump_config.view.id,"init":_vm.currentIndex === index,"jump_link":item.jump_link,"system_page":item.jump_link.jump_config.view.system_page,"from":"st-custom-nav"}})]:[_c('div',{staticClass:"tip-area",style:({
            backgroundColor: _vm.myConfig.normal.contentBgColor,
          })},[_vm._m(1,true)])]]:[_c('div',{staticClass:"tip-area",style:({
          backgroundColor: _vm.myConfig.normal.contentBgColor,
        })},[_vm._m(2,true)])]]})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("自定义导航内容区域")]),_c('p',[_vm._v("在右侧设置链接页面")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("自定义导航内容区域")]),_c('p',[_vm._v("在右侧设置链接页面")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("自定义导航内容区域")]),_c('p',[_vm._v("在右侧设置链接页面")])])}]

export { render, staticRenderFns }