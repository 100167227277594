<template>
  <div class="page-header">
    <!-- TODO: 点击修改透明度 -->
    <div v-if="!isShowTemplate" class="page-header-content">
      <!-- 左侧 -->
      <div class="menu-bar-left" style="min-width: 241px;">
        <!-- 退出编辑按钮：点击时返回到页面装修页 -->
        <el-button class="menu-bar-btn" @click="closeWindow">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/exit.png" />
            <span class="menu-bar-btn-text">退出编辑</span>
          </span>
        </el-button>
      </div>
      <!-- 中部 -->
      <div class="menu-bar-middle">
        <template v-if="!pageset">
          <!-- 导入 -->
          <el-button class="menu-bar-btn" @click="$emit('header-click', 'import')">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/backup.png" />
            <span>导入</span>
          </span>
          </el-button>
          <!-- 弹窗 -->
          <el-button class="menu-bar-btn" @click="$emit('header-click', 'backup')">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/backup.png" />
            <span>备份</span>
          </span>
          </el-button>
        </template>
          <el-button class="menu-bar-btn" @click="handleCommand('exChangeModules')">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/template.png" />
            <span>模板</span>
            <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
          </span>
          </el-button>
        <!-- <el-dropdown @command="handleCommand">
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exChangeModules">更换模板</el-dropdown-item>
            <el-dropdown-item command="checkExample">查看案例</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <!-- TODO: 进入/退出全屏时图标切换 -->
        <el-button class="menu-bar-btn" @click="toggleFullscreen">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/fullscreen.png" />
            <span>{{isFullscreen ? '退出' : ''}}全屏</span>
          </span>
        </el-button>
      </div>
      <!-- 右侧 -->
      <div class="menu-bar-right" style="min-width: 350px;">
        <!-- TODO: 三端预览-->
        <el-button class="menu-bar-btn" @click="$emit('header-click', 'preview')">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/preview.png" />
            <span>预览</span>
          </span>
        </el-button>
        <!--      <el-button class="menu-bar-btn">-->
        <!--            <span class="menu-bar-btn-content">-->
        <!--              <img src="../../assets/images/sync.png"/>-->
        <!--              <span>同步</span>-->
        <!--            </span>-->
        <!--      </el-button>-->
        <el-button class="menu-bar-btn" @click="$emit('header-click', 'save')">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/save.png" />
            <span>保存</span>
          </span>
        </el-button>
        <!-- TODO: 确认弹窗
          微信小程序：是否发布（发布后系统自动提审微信小程序，无需手动提交，审核时间 3 天左右，具体以腾讯审核时间为准）
          H5：是否发布（发布后自动更新 H5 页面）
      -->
        <el-button class="menu-bar-btn" @click="publish">
          <span class="menu-bar-btn-content">
            <img src="../../assets/images/header/release.png" />
            <span>发布</span>
          </span>
        </el-button>
      </div>
    </div>
    <div v-else class="template-header">
      <!-- 左侧 -->
      <div class="header-left">
        <img class="title-icon" src="../../assets/images/header/template.png" />
        <p class="header-title">更换模板</p>
      </div>
      <!-- 右侧 -->
      <div class="header-right">
        <!-- TODO: 搜索模板 -->
<!--        <div class="header-input">-->
<!--          <el-input size="small" class="input-box" v-model="keyword" @keyup.enter.native="handleSearch"-->
<!--            placeholder="搜索你想要的模板" ref="keyword">-->
<!--          </el-input>-->
<!--          <div class="input-icon el-icon-search" @click="handleSearch"></div>-->
<!--        </div>-->
<!--        <img class="close-icon cursor-pointer" @click="$emit('isShowChange',false)"-->
<!--          src="../../assets/images/header/close.png" />-->
        <i @click="$emit('isShowChange',false)"
           class="close-icon el-icon el-icon-close cursor-pointer" style="font-size: 24px;" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageHeaderContent",
  model: {
    prop: "isShowTemplate",
    event: "isShowChange",
  },
  props: {
    isShowTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFullscreen: false,
      keyword: "",
      pageset: this.$route.query.pageset,
    };
  },
  computed: {
    ...mapState("appDesign", ["loading", "templateSearch"]),
  },
  mounted() {
    let that = this;
    this.keyword = this.templateSearch;
    window.onresize = function () {
      if (!that.isFullscreenState()) that.isFullscreen = false;
    };
  },
  methods: {
    /**
     * [isFullscreen 判断浏览器是否全屏]
     * @return [全屏则返回当前调用全屏的元素,不全屏返回false]
     */
    isFullscreenState() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
      );
    },
    /**
     * 模板操作
     */
    handleCommand(key) {
      switch (key) {
        case "exChangeModules":
          this.$emit("header-click", "exChangeModules");
          break;
        case "checkExample":
          this.$emit("header-click", "checkExample");
          break;
      }
    },
    // 模板搜索关键字
    handleSearch() {
      this.$refs.keyword.blur();
      this.$store.commit("appDesign/setTemplateSearch", this.keyword);
    },
    /**
     * 退出编辑
     */
    closeWindow() {
      window.opener = null;
      window.close();
      window.onbeforeunload = null;
    },
    /**
     * 发布
     */
    publish() {
      let platform = this.$route.params.platform;
      let pageset = this.pageset;
      let confirmText = "是否发布（" +
        (platform.includes("weixin")
          ? "发布后系统自动提审微信小程序，无需手动提交，审核时间 3 天左右，具体以腾讯审核时间为准"
          : platform.includes("h5")
            ? "发布后自动更新 H5 微网站页面"
            : "") +
        "）"
      if (pageset && pageset !== 'preview' && pageset !== 'dev' && pageset !== 'release') {
        confirmText = '是否发布此模板到超级后台？'
      }
      this.$confirm(confirmText, "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$emit("header-click", "publish", { platform });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    // 切换显示全屏
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.fullScreen();
      } else {
        this.exitScreen();
      }
      this.$emit("header-click", "fullscreen");
    },
    // 全屏
    fullScreen() {
      var el = document.documentElement;
      var rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      }
    },
    // 退出全屏
    exitScreen() {
      var el = document.documentElement;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header-content {
  display: flex;
  justify-content: space-between;

  .menu-bar-left,
  .menu-bar-middle,
  .menu-bar-right {
    display: flex;
    min-width: 33.3%;
  }

  .menu-bar-left {
    .menu-bar-btn {
      &:first-child {
        margin-left: 17px;
      }
    }
  }

  .menu-bar-middle {
    justify-content: center;
  }

  .menu-bar-right {
    justify-content: flex-end;
  }

  .menu-bar-btn {
    background-color: unset;
    border-color: transparent;
    color: white;
    height: 54px;
    display: flex;
    align-items: center;

    .menu-bar-btn-content {
      display: flex;
      align-items: center;
      height: 20px;

      img {
        width: 30px;
        margin-right: 7px;
      }

      .menu-bar-btn-text {
        line-height: 54px;
      }
    }
  }
}

.template-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 46px;
  height: 54px;
  .header-left {
    display: flex;
    align-items: center;
    .title-icon {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }

    .header-title {
      font-size: 13px;
      color: #ffffff;
      line-height: 18px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-input {
      display: flex;
      align-items: center;
      .input-icon {
        cursor: pointer;
        background-color: #fff;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3576ff;
        padding: 0;
        width: 32px;
        height: 32px;
        border: 1px solid #fff;
        border-left: 0px;
      }
    }

    .close-icon {
      margin-left: 29px;
      color: white;
    }
  }
}

.page-header {
  ::v-deep.el-input--small .el-input__inner {
    background-color: #3576ff;
    padding: 0 10px !important;
    border-radius: 0;
    border-color: #fff;
    color: #fff;
  }
  ::v-deep.el-input-group__append,
  ::v-deep.el-input-group__prepend {
    background-color: #3576ff;
  }
}

.input-box {
  ::v-deep.el-input__inner {
    &::placeholder {
      color: #fff;
    }
  }
}
</style>
