<template>
  <div :class="[myStyleType]" :style="[myRootStyle]" class="container">
    <template v-if="myStyleType === 'template1'">
      <div v-if="myConfig.templateData.showType === 'swiper'">
        <swiper class="show-swiper" ref="mySwiper" :options="options">
          <swiper-slide
            :key="index"
            :style="{
              paddingBottom: swiperData.length > 1 ? '8px' : '0',
            }"
            class="row-item swiper-no-swiping"
            v-for="(item, index) in swiperData.length
              ? swiperData
              : defaultList"
          >
            <div
              :key="i"
              :style="{
                flex: `0 0 ${100 / myConfig.templateData.numLimit}%`,
                marginTop: i >= myConfig.templateData.numLimit ? '12px' : '',
              }"
              class="nav-item"
              v-for="(nav, i) in item"
            >
              <el-image
                :src="fixSpecialImageUrl(nav.icon, '', index, i)"
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
              <p
                :style="{
                  color: myConfig.templateData.textColor,
                  marginTop: myConfig.templateData.textSpacing + 'px',
                }"
                class="nav-name"
              >
                {{ nav.name || `导航${ch_sort[i]}` }}
              </p>
            </div>
          </swiper-slide>
          <div v-show="swiperData.length > 1" class="swiper-pagination"></div>
        </swiper>
      </div>
      <div v-else class="show-fixed">
        <div
          :key="i"
          :style="{
            flex: `0 0 ${100 / myConfig.templateData.numLimit}%`,
            marginTop: i >= myConfig.templateData.numLimit ? '12px' : '',
          }"
          class="nav-item"
          v-for="(nav, i) in myConfig.tabs.list.length
            ? myConfig.tabs.list
            : defaultList"
        >
          <el-image
            :src="fixSpecialImageUrl(nav.icon, '', i)"
            :style="{ borderRadius: borderRadiusString }"
            fit="cover"
            style="overflow: hidden"
          />
          <p
            :style="{
              color: myConfig.templateData.textColor,
              marginTop: myConfig.templateData.textSpacing + 'px',
            }"
            class="nav-name"
          >
            {{ nav.name ? nav.name : `导航${ch_sort[i]}` }}
          </p>
        </div>
      </div>
    </template>
    <!-- 模板二，模版三 -->
    <template v-else-if="['template2', 'template3'].includes(myStyleType)">
      <div class="x-scroll-list">
        <div
          :class="myStyleType"
          :key="i"
          :style="{
            marginRight: `${myConfig.templateData.dataSpacing}px`,
            backgroundColor:
              !nav.icon && myStyleType === 'template2' ? '#F3F5F7' : '',
            borderRadius: myStyleType === 'template2' ? borderRadiusString : '',
          }"
          class="nav-item"
          v-for="(nav, i) in myConfig.tabs.list.length
            ? myConfig.tabs.list
            : defaultList"
        >
          <template v-if="myStyleType === 'template2'">
            <st-load-image :src="nav.icon" class="image" :size="300" />
          </template>
          <template v-if="myStyleType === 'template3'">
            <el-image
              :src="fixSpecialImageUrl(nav.icon, '', i)"
              :style="{
                borderRadius: borderRadiusString,
              }"
              fit="cover"
              style="overflow: hidden"
            />
            <p
              :style="{
                color: myConfig.templateData.textColor,
                marginTop: myConfig.templateData.textSpacing + 'px',
              }"
              class="nav-name"
            >
              {{ nav.name ? nav.name : `导航${ch_sort[i]}` }}
            </p>
          </template>
        </div>
      </div>
    </template>
    <!-- 模版四 -->
    <template v-if="myStyleType === 'template4'">
      <!-- {{ myConfig.templateData.template4StyleType }} -->
      <div class="template4-box">
        <!-- template4StyleType  1: 左边一个右边一个   2：左边两个右边两个   3：左边一个右边两个  4： 左边两个右边一个 -->
        <div
          class="template4-content"
          v-if="myConfig.templateData.template4StyleType === 1"
        >
          <div class="content-item">
            <div class="one-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[0].icon
                      : defaultList[0].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
          <div class="content-item">
            <div class="one-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[1].icon
                      : defaultList[1].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
        </div>
        <div
          class="template4-content"
          v-else-if="myConfig.templateData.template4StyleType === 2"
        >
          <div class="content-item">
            <div class="two-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[0].icon
                      : defaultList[0].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />

              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[1].icon
                      : defaultList[1].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
          <div class="content-item">
            <div class="two-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[2].icon
                      : defaultList[2].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />

              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[3].icon
                      : defaultList[3].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
        </div>
        <div
          class="template4-content"
          v-else-if="myConfig.templateData.template4StyleType === 3"
        >
          <div class="content-item">
            <div class="one-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[0].icon
                      : defaultList[0].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
          <div class="content-item">
            <div class="two-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[1].icon
                      : defaultList[1].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />

              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[2].icon
                      : defaultList[2].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
        </div>
        <div
          class="template4-content"
          v-else-if="myConfig.templateData.template4StyleType === 4"
        >
          <div class="content-item">
            <div class="two-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[0].icon
                      : defaultList[0].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />

              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[1].icon
                      : defaultList[1].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
          <div class="content-item">
            <div class="one-template">
              <el-image
                class="cover"
                :src="
                  fixSpecialImageUrl(
                    myConfig.tabs.list.length
                      ? myConfig.tabs.list[2].icon
                      : defaultList[2].icon,
                    '',
                    1
                  )
                "
                :style="{ borderRadius: borderRadiusString }"
                fit="cover"
                style="overflow: hidden"
              />
            </div>
          </div>
        </div>

        <div
          class="swiper-dots"
          v-if="
            myConfig.tabs.list.length >
            getDotLength(myConfig.templateData.template4StyleType)
          "
        >
          <div class="dots-list">
            <div
              :class="['dot', i === 1 ? 'active' : '']"
              v-for="i in Math.ceil(
                myConfig.tabs.list.length /
                  getDotLength(myConfig.templateData.template4StyleType)
              )"
              :key="i"
            ></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>



<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import componentMixin from '../../mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'

export default {
  name: 'st-image-text-nav',
  mixins: [componentMixin],
  components: {
    StLoadImage,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      defaultIcon: require('../../assets/images/default-icon.png'),
      defaultList: [
        require('../../assets/jsons/default-list.json').imageTextNav,
      ],
      ch_sort: require('../../assets/jsons/ch-sort.json'),
      swiperData: [],
      options: {
        loop: false,
        autoplay: false,
        speed: 500,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          slideChange: () => {},
        },
      },
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          borderRadius: 0,
        },
        templateData: {
          styleType: 'template1',
          // 模版4布局样式
          template4StyleType: 1,
          showType: '',
          rowLimit: 0,
          numLimit: 0,
          textColor: '',
          textSpacing: 6,
          // "borderRadiusConfig": {
          //   "borderRadiusTopLeft": 0,
          //   "borderRadiusTopRight": 0,
          //   "borderRadiusBottomLeft": 0,
          //   "borderRadiusBottomRight": 0
          // },
          borderRadius: 4,
          dataSpacing: 15,
        },
        tabs: {
          list: [
            {
              icon: '',
              name: '',
              is_show: 1,
              jump_link: require('@/modules/app-design/assets/jsons/jump-link.json'),
            },
          ],
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    tabList() {
      return this.myConfig.tabs.list.filter((el) => el.is_show === 1)
    },
    borderRadiusString() {
      // let conf = this.myConfig.templateData.borderRadiusConfig || {};
      // return `${conf.borderRadiusTopLeft || 0}px ${conf.borderRadiusTopRight || 0}px ${conf.borderRadiusBottomRight || 0}px ${conf.borderRadiusBottomLeft || 0}px`;
      return `${this.myConfig.templateData.borderRadius}px`
    },
  },
  watch: {
    myConfig: {
      deep: true,
      handler(val) {
        this.swiperChange()
      },
    },
    'myConfig.normal.rowLimit'(val) {
      this.swiperChange()
    },
    'myConfig.normal.numLimit'(val) {
      this.swiperChange()
    },
    myStyleType(val) {
      if (val === 'template1') {
        this.swiperChange()
      }
    },
  },
  created() {
    if (this.myStyleType === 'template1') {
      this.swiperChange()
    }
  },
  methods: {
    // 获取模版4的每页显示数量
    getDotLength(template4StyleType) {
      if (template4StyleType === 1) {
        return 2
      } else if (template4StyleType === 2) {
        return 4
      } else if (template4StyleType === 3) {
        return 3
      } else if (template4StyleType === 4) {
        return 3
      }
    },
    swiperChange() {
      this.swiperData = []
      let swiperNum = Math.ceil(
        this.tabList.length /
          (this.myConfig.templateData.numLimit *
            this.myConfig.templateData.rowLimit)
      )
      for (let i = 0; i < swiperNum; i++) {
        const list = this.tabList.slice(
          i *
            this.myConfig.templateData.numLimit *
            this.myConfig.templateData.rowLimit,
          this.myConfig.templateData.numLimit *
            this.myConfig.templateData.rowLimit *
            (i + 1)
        )
        this.swiperData.push(list)
      }
      // console.log('图文导航数据', this.swiperData);
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background-size: cover;
  background-position: center;
}
.show-swiper {
  padding: 12px 0;
}
.show-fixed {
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
}
.row-item {
  display: flex;
  flex-wrap: wrap;
}

.swiper-pagination {
  bottom: -14px;
  width: 100%;
  ::v-deep.swiper-pagination-bullet {
    margin-left: 4px;
    height: 4px;
    width: 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
    background-color: var(--main-color);
    opacity: 0.19;

    &.swiper-pagination-bullet-active {
      height: 4px;
      width: 4px;
      opacity: 1;
    }
  }
}

.nav-item {
  text-align: center;

  .el-image {
    width: 50px;
    height: 50px;
    // background-color: #fff;
  }

  .nav-name {
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }
}

.x-scroll-list {
  display: flex;
  overflow-x: hidden;
  padding: 12px 0;

  .nav-item {
    flex-shrink: 0;
    overflow: hidden;
    &.template2 {
      width: 178px;
      height: 95px;
    }
    &.template3 {
      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        // background-color: #fff;
      }

      .nav-name {
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
      }
    }

    &:first-child {
      margin-left: 16px;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }
}
.template4-box {
  .template4-content {
    display: flex;
    padding: 8px;
    .content-item {
      flex: 1;
      height: 218px;
      .one-template {
        height: 100%;
        .cover {
          height: 100%;
          width: 100%;
          // background: #fff;
        }
      }
      .two-template {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cover {
          flex: 1;
          // background: #fff;
        }
        .cover + .cover {
          margin-top: 8px;
        }
      }
    }
    .content-item + .content-item {
      margin-left: 8px;
    }
  }
  .swiper-dots {
    width: 100%;
    padding: 0px 0px 8px;
    position: relative;
    left: calc(50%);
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    .dots-list {
      display: flex;
      .dot + .dot {
        margin-left: 4px;
      }
      .dot {
        width: 4px;
        height: 4px;
        background: var(--main-color);
        border-radius: 2px;
        opacity: 0.19;

        &.active {
          width: 12px;
          height: 4px;
          border-radius: 2px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
