import StAnalysisPage from "@/modules/app-design/components/Preview/st-analysis-page/StAnalysisPage";
import StAnalysisMember from "@/modules/app-design/components/Preview/st-analysis-member/StAnalysisMember";
import StAnalysisOrganization
  from "@/modules/app-design/components/Preview/st-analysis-organization/StAnalysisOrganization";
import StAnalysisArticle from "@/modules/app-design/components/Preview/st-analysis-article/StAnalysisArticle";
import StAnalysisArticleRankings
  from "@/modules/app-design/components/Preview/st-analysis-article-rankings/StAnalysisArticleRankings";
import StAnalysisActivity from "@/modules/app-design/components/Preview/st-analysis-activity/StAnalysisActivity";
import StAnalysisActivityRankings
  from "@/modules/app-design/components/Preview/st-analysis-activity-rankings/StAnalysisActivityRankings";
import StAnalysisMemberPhoto
  from "@/modules/app-design/components/Preview/st-analysis-member-photo/StAnalysisMemberPhoto";
import StAnalysisEnterprise from "@/modules/app-design/components/Preview/st-analysis-enterprise/StAnalysisEnterprise";
import StAnalysisCommunity from "@/modules/app-design/components/Preview/st-analysis-community/StAnalysisCommunity";
import StAnalysisCommunityGroup
  from "@/modules/app-design/components/Preview/st-analysis-community-group/StAnalysisCommunityGroup";
import StAnalysisGoods from "@/modules/app-design/components/Preview/st-analysis-goods/StAnalysisGoods";
import StAnalysisCommunityMember
  from "@/modules/app-design/components/Preview/st-analysis-community-member/StAnalysisCommunityMember";
import StAnalysisResource from "@/modules/app-design/components/Preview/st-analysis-resource/StAnalysisResource";
import StAnalysisResourceMember
  from "@/modules/app-design/components/Preview/st-analysis-resource-member/StAnalysisResourceMember";
import StAnalysisLesson from "@/modules/app-design/components/Preview/st-analysis-lesson/StAnalysisLesson";
import StAnalysisLessonMember
  from "@/modules/app-design/components/Preview/st-analysis-lesson-member/StAnalysisLessonMember";
import StAnalysisGoodsRankings
  from "@/modules/app-design/components/Preview/st-analysis-goods-rankings/StAnalysisGoodsRankings";
import StAnalysisGoodsMember
  from "@/modules/app-design/components/Preview/st-analysis-goods-member/StAnalysisGoodsMember";
import StAnalysisIncome from "@/modules/app-design/components/Preview/st-analysis-income/StAnalysisIncome";
import StAnalysisFee from "@/modules/app-design/components/Preview/st-analysis-fee/StAnalysisFee";
import StAnalysisHistoryFee from "@/modules/app-design/components/Preview/st-analysis-history-fee/StAnalysisHistoryFee";
import StAnalysisNoPaid from "@/modules/app-design/components/Preview/st-analysis-no-paid/StAnalysisNoPaid";
import StAnalysisActivityFee
  from "@/modules/app-design/components/Preview/st-analysis-activity-fee/StAnalysisActivityFee";
import StAnalysisActivityServiceFee
  from "@/modules/app-design/components/Preview/st-analysis-activity-service-fee/StAnalysisActivityServiceFee";
import StAnalysisResourcePaid
  from "@/modules/app-design/components/Preview/st-analysis-resource-paid/StAnalysisResourcePaid";
import StAnalysisPageForUser
  from "@/modules/app-design/components/Preview/st-analysis-page-for-user/StAnalysisPageForUser";
import StAnalysisActivityJoined
  from "@/modules/app-design/components/Preview/st-analysis-activity-joined/StAnalysisActivityJoined";
import StAnalysisActivitySummary
  from "@/modules/app-design/components/Preview/st-analysis-activity-summary/StAnalysisActivitySummary";
import StAnalysisResourceSummary
  from "@/modules/app-design/components/Preview/st-analysis-resource-summary/StAnalysisResourceSummary";
import StAnalysisResourceTotal
  from "@/modules/app-design/components/Preview/st-analysis-resource-total/StAnalysisResourceTotal";
import StAnalysisArticleTotal
  from "@/modules/app-design/components/Preview/st-analysis-article-total/StAnalysisArticleTotal";
import StAnalysisDonation from "@/modules/app-design/components/Preview/st-analysis-donation/StAnalysisDonation";
import StAnalysisCommunityUser
  from "@/modules/app-design/components/Preview/st-analysis-community-user/StAnalysisCommunityUser";
import StAnalysisLessonUser from "@/modules/app-design/components/Preview/st-analysis-lesson-user/StAnalysisLessonUser";
import StAnalysisShopping from "@/modules/app-design/components/Preview/st-analysis-shopping/StAnalysisShopping";
import StAnalysisShoppingRankings
  from "@/modules/app-design/components/Preview/st-analysis-shopping-rankings/StAnalysisShoppingRankings";
import StAnalysisShoppingCategories
  from "@/modules/app-design/components/Preview/st-analysis-shopping-categories/StAnalysisShoppingCategories";
import StAnalysisShoppingSummary
  from "@/modules/app-design/components/Preview/st-analysis-shopping-summary/StAnalysisShoppingSummary";
import StAnalysisAccess from "@/modules/app-design/components/Preview/st-analysis-access/StAnalysisAccess";
import StAnalysisFeeRecords from "@/modules/app-design/components/Preview/st-analysis-fee-records/StAnalysisFeeRecords";
import StAnalysisPositionRecords
  from "@/modules/app-design/components/Preview/st-analysis-position-records/StAnalysisPositionRecords";
import StInternetContentProvider from "@/modules/app-design/components/Preview/StInternetContentProvider";
import StCompanyIndex from '@/modules/app-design/components/Preview/st-company-index/StCompanyIndex'
import StActivityDescription from '../../components/Preview/StActivityComponent'
import StActivityNotices from '../../components/Preview/StActivityComponent'
import StActivityAddresses from '../../components/Preview/StActivityComponent'
import StActivityJoin from '../../components/Preview/StActivityComponent'
import StActivityGuests from '../../components/Preview/StActivityComponent'
import StActivityPlans from '../../components/Preview/StActivityComponent'
import StActivityRelatives from '../../components/Preview/StActivityComponent'
import StActivityUnits from '../../components/Preview/StActivityComponent'
import StActivityTimeline from '../../components/Preview/StActivityComponent'
import StActivityPosts from '../../components/Preview/StActivityComponent'
export default {
StCompanyIndex,
StInternetContentProvider,
StAnalysisPositionRecords,
StAnalysisFeeRecords,
StAnalysisAccess,
StAnalysisShoppingSummary,
StAnalysisShoppingCategories,
StAnalysisShoppingRankings,
StAnalysisShopping,
StAnalysisLessonUser,
StAnalysisCommunityUser,
StAnalysisDonation,
StAnalysisArticleTotal,
StAnalysisResourceTotal,
StAnalysisResourceSummary,
StAnalysisActivitySummary,
StAnalysisActivityJoined,
StAnalysisPageForUser,
StAnalysisResourcePaid,
StAnalysisActivityServiceFee,
StAnalysisActivityFee,
StAnalysisNoPaid,
StAnalysisHistoryFee,
StAnalysisFee,
StAnalysisIncome,
StAnalysisGoodsMember,
StAnalysisGoodsRankings,
StAnalysisLessonMember,
StAnalysisLesson,
StAnalysisResourceMember,
StAnalysisResource,
StAnalysisCommunityMember,
StAnalysisGoods,
StAnalysisCommunityGroup,
StAnalysisCommunity,
StAnalysisEnterprise,
StAnalysisMemberPhoto,
StAnalysisActivityRankings,
StAnalysisActivity,
StAnalysisArticleRankings,
StAnalysisArticle,
StAnalysisOrganization,
  StAnalysisPage,
  StAnalysisMember,
  StActivityPosts,
  StActivityTimeline,
  StActivityUnits,
  StActivityRelatives,
  StActivityPlans,
  StActivityGuests,
  StActivityJoin,
  StActivityAddresses,
  StActivityDescription,
  StActivityNotices,
}
console.log('~新增加的 业务组件/系统页 组件需要在这边导入')
