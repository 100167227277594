var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-contacts"},[_c('div',{staticClass:"header",class:_vm.platform !== 'h5' && _vm.myConfig.normal.bgConfig === 'theme'
        ? 'theme-color'
        : '',style:({
      backgroundColor:
        _vm.platform === 'h5'
          ? ''
          : _vm.myConfig.normal.bgConfig === 'custom' &&
            _vm.myConfig.normal.bgType === 'color'
          ? _vm.myConfig.normal.bgColor
          : '',
    })},[(
        _vm.myConfig.normal.bgConfig === 'custom' &&
        _vm.myConfig.normal.bgType === 'image'
      )?_c('el-image',{staticClass:"bg-image",attrs:{"src":_vm.fixImageUrl(_vm.myConfig.normal.bgImage, { h: 400 }, true),"fit":"cover"}}):_vm._e(),(_vm.platform !== 'h5')?[_c('img',{staticClass:"start-top-img",attrs:{"src":_vm.statusBar[_vm.myConfig.normal.statusBarColor]}}),_c('div',{staticClass:"start-top",staticStyle:{"text-align":"end"}},[(_vm.myConfig.normal.showHeaderJump)?_c('img',{staticClass:"header-jump",class:[
            _vm.headerJump.showConfig.showType !== 'template1'
              ? 'template-back-icon'
              : 'default-back-icon' ],attrs:{"src":_vm.backIcon[_vm.myConfig.normal.statusBarColor][
              _vm.headerJump.showConfig.showType
            ]}}):_vm._e(),_c('img',{staticClass:"start-top-handler",attrs:{"src":_vm.handleIcon[_vm.myConfig.normal.statusBarColor]}})])]:_c('st-page-set',{attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"type-select",class:[_vm.platform === 'h5' ? 'simple' : '']},[(_vm.myConfig.normal.titleConfig.isShow)?_c('div',{staticClass:"type-item",style:({
          color: _vm.myConfig.normal.fontColor,
          fontWeight: _vm.myConfig.normal.fontWeight,
          fontSize: _vm.myConfig.normal.fontSize + 'px',
        })},[_vm._v(" "+_vm._s(_vm.myConfig.normal.titleConfig.name)+" ")]):_vm._e()])],2),(_vm.myConfig.searchConfig.isShow)?_c('div',{staticClass:"search",class:_vm.myConfig.searchConfig.bgConfig === 'theme' ? 'theme-color' : '',style:({
      backgroundColor:
        _vm.myConfig.searchConfig.bgType === 'color'
          ? _vm.myConfig.searchConfig.bgColor
          : '',
      backgroundImage:
        _vm.myConfig.searchConfig.bgType === 'image'
          ? ("url(" + (_vm.fixImageUrl(_vm.myConfig.searchConfig.bgImage, {}, true)) + ")")
          : '',
    })},[_c('div',{staticClass:"header-search",style:({
        height: _vm.myConfig.searchConfig.height + 'px',
        borderRadius:
          _vm.myConfig.searchConfig.searchType === 'circle'
            ? _vm.myConfig.searchConfig.height + 'px'
            : '0px',
        backgroundColor: _vm.myConfig.searchConfig.searchColor,
        color: _vm.myConfig.searchConfig.placeholderColor,
        justifyContent:
          _vm.myConfig.searchConfig.textPosition === 'center' ? 'center' : '',
      })},[_c('div',{staticClass:"search-content"},[_c('p',{staticClass:"search-icon el-icon-search"}),(_vm.myConfig.searchConfig.placeholder.isShow)?_c('p',{staticClass:"search-tip"},[_vm._v(" "+_vm._s(_vm.myConfig.searchConfig.placeholder.name)+" ")]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }