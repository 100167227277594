<template>
  <div class="custom-header">
    <template v-if="['template1'].includes(myConfig.templateData.template)">
      <img
        v-if="platform !== 'h5'"
        class="status-bar"
        :src="statusBar[page.props.normal.statusBarColor]"
      />
    </template>
    <div
      v-if="myConfig.templateData.template === 'template1'"
      class="header-template1"
      :style="{
        paddingBottom: myConfig.contentConfig.showSearch ? '20px' : '',
      }"
    >
      <div class="head">
        <el-image
          class="head-logo"
          fit="cover"
          :src="
            myConfig.contentConfig.icon ||
            require('../../../assets/images/default-icon.png')
          "
        >
        </el-image>
        <p
          class="head-title"
          v-if="myConfig.contentConfig.titleConfig.isShow"
          :style="{
            fontWeight: myConfig.contentConfig.fontWeight,
            fontSize: myConfig.contentConfig.fontSize + 'px',
            lineHeight: myConfig.contentConfig.fontSize + 'px',
            color: myConfig.contentConfig.fontColor,
          }"
        >
          {{ myConfig.contentConfig.titleConfig.name }}
        </p>
        <img
          v-if="myConfig.gov.isEnableOthers"
          src="../../../assets/images/st-custom-header/dropdown-icon.png"
          fit="contain"
          style="width: 7px; height: 3px; margin-left: 6px"
        />
        <el-image
          v-if="platform !== 'h5'"
          class="handle-icon"
          :src="handleIcon[page.props.normal.statusBarColor]"
        />
      </div>
      <div
        class="search"
        :style="{
          borderRadius:
            myConfig.searchConfigTemplate1.searchType === 'circle'
              ? '18px'
              : '6px',
          justifyContent:
            myConfig.searchConfigTemplate1.textPosition === 'center'
              ? 'center'
              : '',
          backgroundColor: myConfig.searchConfigTemplate1.searchColor,
        }"
        v-if="myConfig.contentConfig.showSearch"
      >
        <div class="search-box">
          <!-- <el-image class="search-icon" :src="require('../../assets/images/search.png')" fit="cover">
          </el-image> -->
          <i
            class="search-icon el-icon-search"
            :style="{
              color: myConfig.searchConfigTemplate1.placeholderColor,
            }"
          />
          <p
            class="search-placeholder"
            :style="{
              color: myConfig.searchConfigTemplate1.placeholderColor,
            }"
          >
            {{
              myConfig.searchConfigTemplate1.placeholder
                ? myConfig.searchConfigTemplate1.placeholder
                : '请输入搜索内容'
            }}
          </p>
        </div>
      </div>
    </div>
    <template
      v-else-if="
        !['template5', 'template6'].includes(myConfig.templateData.template)
      "
    >
      <div
        class="header-banner"
        :class="[myConfig.templateData.template]"
        v-if="myConfig.templateData.template !== 'template1'"
        v-loading="myConfig.templateData.template !== 'template7' && loading"
      >
        <!-- <el-image class="status-bar" :src="require('../../assets/images/phone-statusbar1@2x.png')" fit="cover">
        </el-image> -->
        <!-- 状态栏 -->
        <template v-if="platform !== 'h5'">
          <div style="height: 20px"></div>
          <el-image
            class="status-bar"
            :src="statusBar[page.props.normal.statusBarColor]"
          />
        </template>
        <template
          v-else-if="
            myConfig.templateData.template === 'template3' ||
            myConfig.templateData.template === 'template4'
          "
        >
          <div style="height: 32px"></div>
        </template>
        <div
          v-if="myConfig.templateData.template !== 'template7'"
          class="header-top"
          style="padding-right: 7px; padding-top: 4px; padding-left: 20px"
          :style="{
            paddingBottom: `${
              myConfig.templateData.template === 'template2'
                ? platform === 'h5'
                  ? 174
                  : 154
                : myConfig.templateData.template === 'template3'
                ? platform === 'h5'
                  ? 196
                  : 176
                : platform === 'h5'
                ? 256
                : 236
            }px`,
          }"
        >
          <div
            v-if="
              myConfig.bannerSearchConfig.isShow &&
              myConfig.templateData.template === 'template2'
            "
            class="search template2"
            :style="{
              borderRadius:
                (myConfig.bannerSearchConfig.template2_searchType ===
                  'circle' &&
                  myConfig.templateData.template === 'template2') ||
                (myConfig.bannerSearchConfig.template4_searchType ===
                  'circle' &&
                  myConfig.templateData.template === 'template4')
                  ? '18px'
                  : '6px',
              justifyContent:
                myConfig.bannerSearchConfig.textPosition === 'center'
                  ? 'center'
                  : '',
              flex: platform === 'h5' ? 1 : '',
            }"
          >
            <el-image
              class="search-icon"
              :src="require('../../../assets/images/white-search-icon.png')"
              fit="cover"
            >
            </el-image>
            <p class="search-placeholder">
              {{
                myConfig.bannerSearchConfig.placeholder
                  ? myConfig.bannerSearchConfig.placeholder
                  : '搜索'
              }}
            </p>
          </div>
          <div
            v-else-if="
              myConfig.bannerSearchConfig.isShow &&
              myConfig.templateData.template === 'template4'
            "
            class="search template4"
            :style="{
              borderRadius:
                myConfig.bannerSearchConfig.searchType === 'circle'
                  ? '18px'
                  : '4px',
            }"
          >
            <i class="search-icon el-icon-search" />
            <p class="search-placeholder">
              {{
                myConfig.bannerSearchConfig.placeholder
                  ? myConfig.bannerSearchConfig.placeholder
                  : '搜索'
              }}
            </p>
          </div>
          <div v-else></div>
          <el-image
            v-if="platform !== 'h5'"
            class="handle-icon"
            :src="handleIcon[page.props.normal.statusBarColor]"
          />
        </div>
        <!-- 模板七的导航栏 -->
        <div class="header-top flex-between flex-align-center" style="height: 44px;padding-right: 10px;" v-else>
          <!-- 自定义导航栏高度（不含状态栏） -->
          <div class="search-bar" style="top: 27px; left: 10px" v-if="false">
            <i class="el-icon el-icon-search" />
            <span
              style="margin-left: 6px"
              v-if="myConfig.searchConfigTemplate1.placeholder"
              >{{ myConfig.searchConfigTemplate1.placeholder }}</span
            >
          </div>
          <div v-else></div>
          <el-image
            :src="handleIcon[page.props.normal.statusBarColor]"
            class="handle-icon"
            v-if="platform !== 'h5'"
          />
        </div>
      </div>
      <div
        :class="{ clipPath: myConfig.templateData.template === 'template3' }"
        class="banner-content"
        v-if="myConfig.templateData.template !== 'template1' && myConfig.templateData.template !== 'template7'"
        :style="{
          backgroundColor: advert_list.length ? '' : '#777777',
          height:
            myConfig.templateData.template === 'template2'
              ? ''
              : myConfig.templateData.template === 'template4'
              ? '292px'
              : '232px',
        }"
      >
        <el-image
          v-if="!advert_list.length"
          class="default-icon"
          :src="require('../../../assets/images/mini-default-icon@2x.png')"
        >
        </el-image>
        <el-image
          v-if="advert_list.length"
          class="advert-img"
          :src="
            fixImageUrl(
              advert_list[0].banner_type === 3
                ? advert_list[0].video.thumbnail
                : advert_list[0].image,
              { h: 400 },
              true
            )
          "
          fit="cover"
        >
        </el-image>
        <div
          class="title-cover"
          :class="myConfig.templateData.template"
          v-if="!advert_list.length || advert_list[0].cover_title"
        >
          {{ advert_list.length ? advert_list[0].cover_title : '请输入内容' }}
        </div>
        <div
          class="swiper-dots"
          :class="myConfig.templateData.template"
          v-if="advert_list.length !== 1"
        >
          <div
            :class="['item-dot', i === 1 ? 'active' : '']"
            v-for="i in advert_list.length ? advert_list.length : 4"
            :key="i"
          ></div>
        </div>
      </div>

      <!-- 模板七 -->
      <div :style="{
        paddingTop: `calc(100px + ${myConfig.contentConfigTemplate7.coverHeight}px)`
      }" class="flex" style="padding-left: 16px;" v-if="myConfig.templateData.template === 'template7'">
        <el-image :src="fixImageUrl(myConfig.contentConfigTemplate7.icon, {w: 500})" fit="contain" style="height: 48px; width: auto;" />
      </div>
    </template>
    <div
      v-else-if="myConfig.templateData.template === 'template5'"
      class="template5"
    >
      <!-- 模板五 -->
      <img
        v-if="platform !== 'h5'"
        class="status-bar"
        style="position: absolute; top: 0; left: 0"
        :src="statusBar[page.props.normal.statusBarColor]"
        :style="{
          'background-image':
            template5Config.bgImg && false
              ? `url(${fixImageUrl(template5Config.bgImg, { w: 900 }, true)})`
              : '',
        }"
      />
      <div class="head">
        <div
          class="search-bar"
          style="top: 27px; left: 10px"
          :style="{
            'border-radius':
              myConfig.searchConfigTemplate1.searchType === 'circle'
                ? '18px'
                : '6px',
            justifyContent:
              myConfig.searchConfigTemplate1.textPosition === 'center'
                ? 'center'
                : '',
            backgroundColor: myConfig.searchConfigTemplate1.searchColor,
            color: myConfig.searchConfigTemplate1.placeholderColor,
          }"
        >
          <i class="el-icon el-icon-search" />
          <span
            v-if="myConfig.searchConfigTemplate1.placeholder"
            style="margin-left: 6px"
            >{{ myConfig.searchConfigTemplate1.placeholder }}</span
          >
        </div>
        <el-image
          v-if="platform !== 'h5'"
          class="handle-icon"
          style="position: absolute; top: 27px; right: 10px"
          :src="handleIcon[page.props.normal.statusBarColor]"
        />
      </div>
      <div class="poster flex-col">
        <img
          class="bg-image"
          :src="
            template5Config.bgImg
              ? fixImageUrl(template5Config.bgImg, { w: 900 }, true)
              : require('../../../assets/images/default-icon.png')
          "
          :style="{
            'object-fit': template5Config.bgImg ? '' : 'none',
            'background-color': template5Config.bgImg
              ? ''
              : 'rgb(243, 245, 247)',
          }"
        />
        <div
          class="linear-gradient flex-col"
          :style="{
            background: template5Config.linearGradientConfig.enable
              ? `linear-gradient(180deg, rgba(255, 112, 81, 0) 0%, ${template5Config.linearGradientConfig.color} 100%)`
              : '',
            height:
              template5Config.titleConfig.isShow &&
              template5Config.titleConfig.name
                ? '121px'
                : '77px',
          }"
        >
          <div class="icon-title flex">
            <img
              class="icon"
              :src="
                template5Config.icon ||
                require('../../../assets/images/default-icon.png')
              "
            />
            <span
              v-if="template5Config.businessNameConfig.isShow"
              class="clamp2"
              :style="{
                fontWeight: template5Config.businessNameConfig.fontWeight,
                fontSize: template5Config.businessNameConfig.fontSize + 'px',
                color: template5Config.businessNameConfig.fontColor,
              }"
              >{{ template5Config.businessNameConfig.name }}</span
            >
          </div>
          <h3
            v-if="
              template5Config.titleConfig.isShow &&
              template5Config.titleConfig.name
            "
            class="sub-title"
            :style="{
              fontWeight: template5Config.titleConfig.fontWeight,
              fontSize: template5Config.titleConfig.fontSize + 'px',
              color: template5Config.titleConfig.fontColor,
            }"
          >
            {{ template5Config.titleConfig.name }}
          </h3>
        </div>
      </div>
      <div
        class="join flex-col"
        :style="{
          'background-color': template5Config.linearGradientConfig.color,
        }"
      >
        <p
          v-if="template5Config.descriptionConfig.isShow"
          class="desc"
          :style="{
            fontWeight: template5Config.descriptionConfig.fontWeight,
            fontSize: template5Config.descriptionConfig.fontSize + 'px',
            color: template5Config.descriptionConfig.fontColor,
          }"
        >
          {{ template5Config.descriptionConfig.name }}
        </p>
        <div class="join-bar flex-between">
          <div class="flex-center">
            <st-avatars :arr="peopleData.list" :width="24" />
            <span
              :style="{
                color: template5Config.memberCountConfig.fontColor || '#fff',
                fontWeight:
                  template5Config.memberCountConfig.fontWeight || 'normal',
                fontSize:
                  template5Config.memberCountConfig.fontSize + 'px' || '14px',
              }"
              class="join-people"
              style="margin-left: 12px"
              v-if="template5Config.memberCountConfig.isShow"
              >{{ peopleData.count }}
              {{ template5Config.memberCountConfig.name }}</span
            >
          </div>
          <div class="join-btn">申请入会</div>
        </div>
      </div>
    </div>
    <div
      v-else-if="myConfig.templateData.template === 'template6'"
      class="template5"
    >
      <!-- 模板六 -->
      <img
        v-if="platform !== 'h5'"
        class="status-bar"
        style="position: absolute; top: 0; left: 0"
        :src="statusBar[page.props.normal.statusBarColor]"
        :style="{
          'background-image':
            template6Config.bgImg && false
              ? `url(${fixImageUrl(template6Config.bgImg, { w: 900 }, true)})`
              : '',
        }"
      />
      <div class="head">
        <div
          class="search-bar"
          style="top: 27px; left: 10px"
          :style="{
            'border-radius':
              myConfig.searchConfigTemplate1.searchType === 'circle'
                ? '18px'
                : '6px',
            justifyContent:
              myConfig.searchConfigTemplate1.textPosition === 'center'
                ? 'center'
                : '',
            backgroundColor: myConfig.searchConfigTemplate1.searchColor,
            color: myConfig.searchConfigTemplate1.placeholderColor,
          }"
        >
          <i class="el-icon el-icon-search" />
          <span
            v-if="myConfig.searchConfigTemplate1.placeholder"
            style="margin-left: 6px"
            >{{ myConfig.searchConfigTemplate1.placeholder }}</span
          >
        </div>
        <el-image
          v-if="platform !== 'h5'"
          class="handle-icon"
          style="position: absolute; top: 27px; right: 10px"
          :src="handleIcon[page.props.normal.statusBarColor]"
        />
      </div>
      <div class="poster flex-col">
        <img
          class="bg-image"
          :src="
            advert_list.length
              ? fixImageUrl(advert_list[0].image, { w: 900 }, true)
              : require('../../../assets/images/default-icon.png')
          "
          :style="{
            'object-fit': advert_list.length ? 'cover' : 'none',
            'background-color': advert_list.length ? '' : 'rgb(243, 245, 247)',
            height: '100%',
          }"
        />
        <div class="swiper-doi">
          <div
            :class="[i === 0 ? 'active' : '']"
            :key="i"
            class="doi-item"
            v-for="(item, i) in advert_list"
          ></div>
        </div>
        <div
          class="linear-gradient flex-col"
          :style="{
            background: template6Config.linearGradientConfig.enable
              ? `linear-gradient(180deg, rgba(255, 112, 81, 0) 0%, ${template6Config.linearGradientConfig.color} 100%)`
              : '',
            height:
              template6Config.titleConfig.isShow &&
              template6Config.titleConfig.name
                ? '121px'
                : '77px',
          }"
        >
          <div class="icon-title flex">
            <img
              class="icon"
              :src="
                template6Config.icon ||
                require('../../../assets/images/default-icon.png')
              "
            />
            <span
              v-if="template6Config.businessNameConfig.isShow"
              class="clamp2"
              :style="{
                fontWeight: template6Config.businessNameConfig.fontWeight,
                fontSize: template6Config.businessNameConfig.fontSize + 'px',
                color: template6Config.businessNameConfig.fontColor,
              }"
              >{{ template6Config.businessNameConfig.name }}</span
            >
          </div>
          <h3
            v-if="
              template6Config.titleConfig.isShow &&
              template6Config.titleConfig.name
            "
            class="sub-title"
            :style="{
              fontWeight: template6Config.titleConfig.fontWeight,
              fontSize: template6Config.titleConfig.fontSize + 'px',
              color: template6Config.titleConfig.fontColor,
            }"
          >
            {{ template6Config.titleConfig.name }}
          </h3>
        </div>
      </div>
      <div
        class="join flex-col"
        :style="{
          'background-color': template6Config.linearGradientConfig.color,
        }"
      >
        <p
          v-if="template6Config.descriptionConfig.isShow"
          class="desc"
          :style="{
            fontWeight: template6Config.descriptionConfig.fontWeight,
            fontSize: template6Config.descriptionConfig.fontSize + 'px',
            color: template6Config.descriptionConfig.fontColor,
          }"
        >
          {{ template6Config.descriptionConfig.name }}
        </p>
        <div class="join-bar flex-between">
          <div class="flex-center">
            <st-avatars :arr="peopleData.list" :width="24" />
            <span
              :style="{
                color: template6Config.memberCountConfig.fontColor || '#fff',
                fontWeight:
                  template6Config.memberCountConfig.fontWeight || 'normal',
                fontSize:
                  template6Config.memberCountConfig.fontSize + 'px' || '14px',
              }"
              class="join-people"
              style="margin-left: 12px"
              v-if="template6Config.memberCountConfig.isShow"
              >{{ peopleData.count }}
              {{ template6Config.memberCountConfig.name }}</span
            >
          </div>
          <div class="join-btn">申请入会</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { advertList } from '../../../api/page'
import StAvatars from '@/modules/app-design/components/Preview/Common/StAvatars'
import { getCustomHeaderPeople } from '../../../api/components'

export default {
  name: 'st-custom-header',
  components: { StAvatars },
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
    page: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    const model = JSON.parse(JSON.stringify(require('./props')))
    // model.contentConfigTemplate7.icon = ''//但是这样就没有默认图标看了

    return {
      platform: this.$route.params.platform,
      loading: false,
      advert_list: [],
      // 状态栏图片
      statusBar: {
        black: require('../../../assets/images/phone-statusbar1@2x.png'),
        white: require('../../../assets/images/phone-statusbar2@2x.png'),
      },
      /* 头部右侧胶囊样式图标 */
      handleIcon: {
        black: require('../../../assets/images/wechat-handle1@2x.png'),
        white: require('../../../assets/images/wechat-handle2@2x.png'),
      },
      // 定义 config 格式和默认数据
      model,
      peopleData: {
        list: [],
        count: 0,
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    template5Config() {
      return this.myConfig.contentConfig.template5 || {}
    },
    template6Config() {
      return this.myConfig.contentConfig.template6 || {}
    },
  },
  watch: {
    'myConfig.templateData.template'(val) {
      if (val !== 'template1') this.getAdvertList(false)
    },
    'myConfig.loadingConfig.limit'(val) {
      if (this.myConfig.templateData.template !== 'template1')
        this.getAdvertList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      if (this.myConfig.templateData.template !== 'template1')
        this.getAdvertList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (
        JSON.stringify(val) !== JSON.stringify(oldVal) &&
        this.myConfig.templateData.template !== 'template1'
      ) {
        this.getAdvertList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val) {
      if (this.myConfig.templateData.template !== 'template1') {
        this.getAdvertList(false)
      }
    },
    'myConfig.dataConfig.dataFilter'(val) {
      if (this.myConfig.templateData.template !== 'template1') {
        this.getAdvertList(false)
      }
    },
  },
  created() {
    if (this.myConfig.templateData.template === 'template5') {
      this.getPeople()
    } else if (this.myConfig.templateData.template !== 'template1') {
      this.getAdvertList()
    }
  },
  methods: {
    getPeople() {
      this.loading = true
      getCustomHeaderPeople()
        .then((res) => {
          this.peopleData = res.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    //获取广告列表数据
    getAdvertList(notCancel) {
      if (['template7'].includes(this.myConfig.templateData.template)) return
      this.loading = true
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        limit: this.myConfig.loadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        ids,
        category_all:
          this.myConfig.dataConfig.dataType === 'select' ||
          this.myConfig.dataConfig.dataAll ||
          this.myConfig.dataConfig.dataFilter.length == 0
            ? 1
            : 0,
        category_ids: this.myConfig.dataConfig.dataAll
          ? []
          : this.myConfig.dataConfig.dataFilter,
        banner_type_all: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        banner_types:
          this.myConfig.dataConfig.dataType === 'select'
            ? [0, 1, 2]
            : this.myConfig.dataConfig.type,
      }
      advertList(requestData, notCancel)
        .then((res) => {
          const { data } = res
          this.advert_list = data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-header {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  .status-bar {
    width: 100%;
  }

  .template5 {
    .status-bar {
      top: 0;
      left: 0;
    }

    .search-bar {
      height: 30px;
      line-height: 30px;
      padding: 0 17px;
      color: #b3b3b3;
    }

    .status-bar,
    .handle-icon,
    .search-bar {
      position: absolute;
      z-index: 1;
    }

    .poster {
      position: relative;
      height: 343px;
      overflow: hidden;
      background-color: rgb(243, 245, 247);

      .bg-image {
        width: 100%;
      }
      .swiper-doi {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 10;
        .doi-item {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #fff;
          opacity: 0.5;
        }
        .active {
          opacity: 1;
        }
        .doi-item + .doi-item {
          margin-left: 5px;
        }
      }
    }

    .linear-gradient {
      width: 100%;
      height: 121px;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 31px 16px 0;

      .icon-title {
        height: 46px;
        align-items: center;

        .icon {
          width: 46px;
          height: 46px;
          margin-right: 12px;
          object-fit: contain;
        }
      }

      .sub-title {
        line-height: 36px;
        margin-top: 8px;
        @include nowrap();
      }
    }

    .join {
      padding: 4px 16px;
      position: relative;

      /**
       * 解决有缝隙的问题
       */
      &::before,
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        background-color: inherit;
      }

      &::before {
        top: -1px;
      }

      &::after {
        /*        bottom: -1px;*/
      }

      .desc {
        line-height: 24px;
      }

      .join-bar {
        padding: 12px 0;

        .join-people {
          font-size: 14px;
          color: #ffffff;
          line-height: 1.5;
        }

        .join-btn {
          width: 78px;
          height: 31px;
          background: #f7dfc5;
          border-radius: 24px 24px 24px 24px;
          font-size: 14px;
          color: #8c5632;
          line-height: 31px;
          text-align: center;
        }
      }
    }
  }
}

.header-template1 {
  padding: 7px 10px 0 20px;

  .head {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    width: 100%;
    .head-logo {
      width: 29px;
      height: 29px;
      margin-right: 8px;
    }
  }

  .search {
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    height: 32px;
    padding: 0 15px;
    background-color: #f8fbff;

    .search-box {
      display: flex;
      align-items: center;
    }

    .search-icon {
      font-size: 14px;
      margin-right: 4px;
    }

    .search-placeholder {
      font-size: 14px;
      line-height: 14px;
      color: #979da6;
    }
  }
}
.header-banner {
  position: relative;
  z-index: 5;

  &:not(.template7) {
    min-height: 100px;
  }

  .status-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 20px;
    z-index: 2;
  }

  .header-top {
    display: flex;
    align-items: center;
    position: relative;

    .search {
      display: flex;
      align-items: center;
      &.template2 {
        margin-right: 10px;
        width: 130px;
        height: 32px;
        padding: 0 15px;
        border: 1px solid #ffffff;
        .search-icon {
          width: 13px;
          height: 13px;
          margin-right: 4px;
        }
      }

      &.template4 {
        position: absolute;
        bottom: 16px;
        left: 16px;
        width: 343px;
        height: 36px;
        justify-content: center;
        background-color: #fff;
        .search-placeholder {
          color: #b3b3b3;
        }
        .search-icon {
          color: #b3b3b3;
          font-size: 14px;
          margin-right: 4px;
        }
      }

      .search-box {
        display: flex;
        align-items: center;
      }

      .search-placeholder {
        font-size: 14px;
        line-height: 14px;
        color: #fff;
      }
    }
  }
}

.handle-icon {
  display: block;
  width: 87px;
  height: 32px;
  margin-left: auto;
}

.banner-content {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  &.clipPath {
    clip-path: circle(1500px at 50% -1278px);
  }

  .advert-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .title-cover {
    position: absolute;
    padding: 20px 17px 10px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;

    &.template2 {
      bottom: 0;
      height: 75px;
      background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &.template3 {
      bottom: 0;
      height: 101px;
      background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &.template4 {
      top: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    font-weight: bold;
    color: #ffffff;
  }

  .swiper-dots {
    position: absolute;
    display: flex;
    &.template2 {
      bottom: 8px;
      right: 12px;

      .item-dot + .item-dot {
        margin-left: 4px;
      }
      .item-dot {
        width: 8px;
        height: 4px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        &.active {
          width: 12px;
          height: 4px;
          background: #ffffff;
          border-radius: 2px;
        }
      }
    }
    &.template3 {
      bottom: 36px;
      left: 21px;
      .item-dot + .item-dot {
        margin-left: 6px;
      }
      .item-dot {
        width: 4px;
        height: 4px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        &.active {
          width: 4px;
          height: 4px;
          background: #ffffff;
          border-radius: 4px;
        }
      }
    }
    &.template4 {
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      .item-dot + .item-dot {
        margin-left: 6px;
      }
      .item-dot {
        width: 6px;
        height: 6px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 6px;
        &.active {
          width: 6px;
          height: 6px;
          background: #ffffff;
          border-radius: 6px;
        }
      }
    }
  }
}

.default-icon {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 50%;
  left: 50%;
  margin-top: -14px;
  margin-left: -14px;
  z-index: 2;
}
</style>
