<template>
    <div class="st-video" :style="{ marginTop: `${myConfig.normal.marginTop}px`,width: `calc(100% - ${myConfig.normal.rowMargin*2}px)`}">

        <el-image class="st-video-img" :src="myConfig.dataConfig.video.thumbnail"
            :fit="myConfig.dataConfig.video.thumbnail ? 'cover' : 'none'" >
        </el-image>
        <i v-show="myConfig.dataConfig.video.thumbnail" class="el-icon-caret-right"></i>
    </div>
</template>
  
<script>
import MiniProgramViewer from "@/base/components/Preview/MiniProgramViewer";
import componentMixin from "../../mixins/component-mixin";
export default {
    name: "st-video",
    components: { MiniProgramViewer },
    mixins: [componentMixin],
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            model: {
                normal: {
                    marginTop: 0,
                    rowMargin:0
                },
                dataConfig: {
                    "video": {
                        "url": "",
                        "thumbnail": "",
                        "type": "video"
                    },
                }
            }
        }
    },
    computed: {
        myConfig() {
            console.log(this.config);
            return this.$mergeModel(this.model, this.config);
        },
    },
    created() { },
    methods: {},
};
</script>
<style lang="scss" scoped>
.st-video {
    width: 100%;
    margin: auto;
    height: 185px;
    overflow: hidden;
    position: relative;
    .st-video-img {
        // background-color: black;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;        
        transform: translate(-50%, -50%);
        object-fit: none;
        ::v-deep.el-image__inner {
            background-color: #f1f3f6;
        }
    }
    .el-icon-caret-right{
        font-size: 50px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;        
        transform: translate(-50%, -50%);
    }



}
</style>